import React from 'react';
import RoomHome from '../components/Rooms/RoomHome';
import RoomCard from '../components/Rooms/RoomCard';
import Faq from '../components/Contact/Faq';
import {  RightOutlined } from '@ant-design/icons';
import { useGetRoomsQuery } from '../api/roomsApi';
const Rooms = () => {
  const { data: rooms, isLoading, isError } = useGetRoomsQuery();

  if (isLoading) {
    return <div className="text-center fs-2">Loading...</div>;
  }

  if (isError) {
    return (
      <div className="text-center text-red-600 fs-2">Something went error!</div>
    );
  }

  return (
    <>
      <RoomHome />
      {rooms.map((room) => (
        <RoomCard key={room.id} room={room} activeLevels={10} />
      ))}
      <Faq />
      <div className="flex justify-center my-8 mx-auto">
        <button
          className="btn py-3 text-[#F4EB19] tracking-wider px-4 rounded-3xl   font-semibold border-[#F4EB19] flex items-center"
          style={{ border: '3px solid ' }}
        >
          VIEW ALL FAQS
          <RightOutlined className="ml-3 size-4" />
        </button>
      </div>
    </>
  );
};

export default Rooms;
