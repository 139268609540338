import React from "react";
import { Link } from "react-router-dom";
import clock from "../../assets/clock.png";
import pic2 from "../../assets/pic2.png";
import players from "../../assets/players.png";

const Service = () => {
  return (
    <>
      <div className="relative bg-[#0A0A0A] p-8 max-lg:p-5">
        <img src={pic2} width="" className="" />

        <div className="mt-8 mb-4">
          <h2 className="text-2xl font-semibold text-[#F3DB29] mb-2">
            Birthdays program
          </h2>
          <p className="text-lg text-[#E9E5DD] mt-3">
            Lorem ipsum dolor sit amet, consectetur elit, sed do eius.....
          </p>

          <div className="grid grid-cols-2 max-xl:grid-cols-2 gap-2 my-4 text-sm px-9 max-lg:px-6">
            <div className="flex items-center">
              <img src={players} width="15px" className="" />
              <p className="text-[#CDC9C1] ml-2">3-7 players</p>
            </div>

            <div className="flex items-center ml-2">
              <img src={clock} width="15px" className="" />
              <p className="text-[#CDC9C1] ml-2">60 mins</p>
            </div>
          </div>

          <div className="flex justify-center mx-auto mt-7">
            <Link
              to="/roomsview"
              className="py-3 px-9 max-lg:px-6 rounded-3xl bg-[#C7A807] text-[#EFEFEF] tracking-wider text-lg"
            >
              DETAILS
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Service;
