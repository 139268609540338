import React from 'react';
import Servicestitle from '../Servicestitle';
import '../../styles/Services.css';
import '../../styles/contact.css';
import pin from '../../assets/pin.png';
import call from '../../assets/call.png';
import envelope from '../../assets/envelope.png';
import { Link } from 'react-router-dom';
import LabelForm from '../LabelForm';
import { useSelector } from 'react-redux';

const ContactHome = () => {
  const { header, email, phoneNumber, address } = useSelector(
    (state) => state.contactContent
  );

  return (
    <>
      <div className="bgAbout "></div>
      <div className="text-center h-[60vh]  mt-44">
        <Servicestitle headtitle="REACH OUT & UNLOCK THE UNKNOWN" />
      </div>

      <div className="get-touch">
        <h2>{header?.title_en}</h2>
        <div className="p-12">
          <div className="items-center space-x-48 justify-center mr-6 max-lg:mr-0 flex align-center  ">
            <Link
              to={`mailto:${email}`}
              className={`flex items-center  mr-3 max-lg:mr-1`}
            >
              <img src={envelope} className="size-6" alt="envelpoe" />
              {/* <MailFilled className={`size-3 `} /> */}
              <span className="icon-text ml-3  max-lg:hidden">
                {email?.title_en}
              </span>
            </Link>
            <Link
              to={`tel:${phoneNumber?.title_en}`}
              className={`flex items-center  mr-3 max-lg:mr-1`}
            >
              {/* <PhoneFilled className={`size-3 `} /> */}
              <img src={call} className="size-6" alt="call" />
              <span className=" icon-text ml-3  max-lg:hidden">
                {phoneNumber?.title_en}
              </span>
            </Link>
            <p className={`flex items-center mr-3 max-lg:mr-1 `}>
              {/* <EnvironmentTwoTone className={`size-3 `} /> */}
              <img src={pin} className="size-6" alt="address" />
              <span className=" icon-text ml-3  max-lg:hidden">
                {address?.title_en}
              </span>
            </p>
          </div>
        </div>
        <LabelForm />
      </div>

      {/* <Servicesubtitle headsubtitle="They made it.. Trust in YOU" />
      <SwiperComponents />
      <Faq /> */}
    </>
  );
};

export default ContactHome;
