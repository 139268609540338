import React from 'react'
import Servicescomponent from '../components/Servicescomponent'

const Services = () => {
  return (
    <>
      <Servicescomponent />
    </>
  )
}

export default Services