import React from "react";
import { Link } from "react-router-dom";
import clock from "../../assets/clock.png";
import pic2 from "../../assets/pic2.png";
import { Rate } from "antd";
import skeleton from "../../assets/skeleton.png";
import players from "../../assets/players.png";
// import "../styles/Explain.css";

const Room = ({ activeLevels }) => {

  const totalLevels = 15;

  // Generate the divs dynamically
  const levelDivs = Array.from({ length: totalLevels }, (_, index) => {
    return (
      <div
        key={index}
        className={`h-5 w-2 rounded-sm mr-2 ${
          index < activeLevels ? "bg-[#D6B715]" : "bg-[#333333]"
        }`}
      ></div>
    );
  });

  return (
    <>
      <div className="relative hover:bg-[#0A0A0A] p-8 max-lg:p-5">
        <img src={pic2} width="" className="" />

        <div className="mt-8 mb-4">
          <h2 className="text-2xl font-semibold primary_color mb-2">Asylum</h2>
          <Rate
            disabled
            defaultValue={5}
            style={{ fontSize: "10px" }}
            className="custom-rate"
          />
          <p className="text-lg text-[#E9E5DD] mt-3">
            Lorem ipsum dolor sit amet, consectetur elit, sed do eius.....
          </p>

          <div className="grid grid-cols-3 max-xl:grid-cols-2 gap-2 my-4 text-sm px-2">
            <div className="flex items-center">
              <img src={players} width="15px" className="" />
              <p className="text-[#CDC9C1] ml-2">3-7 players</p>
            </div>

            <div className="flex items-center ml-2">
              <img src={clock} width="15px" className="" />
              <p className="text-[#CDC9C1] ml-2">60 mins</p>
            </div>

            <div className="flex items-center">
              <img src={skeleton} width="17px" className="" />
              <p className="text-[#CDC9C1] ml-2">Horror</p>
            </div>
          </div>

          <div className="ml-6 max-lg:ml-3 primary_color">
            <p>Difficulty level</p>
            <div className="flex items-center mt-1">
              <div className="flex items-center">
                {levelDivs}
              </div>
              <p className="text-sm">Hard</p>
            </div>
          </div>

          <div className="flex justify-center mx-auto mt-7">
            <Link
              to="/booknow"
              className="py-3 px-6 rounded-3xl bg-[#C7A807] text-[#EFEFEF] tracking-wider text-lg"
            >
              BOOK ROOM
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Room;
