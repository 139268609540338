import React from 'react'

const HardLevels = ({ activeLevels  }) => {
    const totalLevels = 15;

  // Generate the divs dynamically
  const levelDivs = Array.from({ length: totalLevels }, (_, index) => {
    return (
      <div
        key={index}
        className={`h-5 w-2 rounded-sm mr-2 ${
          index < activeLevels ? "bg-[#D6B715]" : "bg-[#333333]"
        }`}
      ></div>
    );
  });
  return (
    <div className=" flex  items-center justify-center mt-10">
    <p className="difficult my-6 text-left mr-5">Difficulty level</p>
    <div>
    <div className="flex items-center">{levelDivs}</div>
    </div>
    <p className="difficult my-6 text-left ml-3">Hard</p>
  </div>
  )
}

export default HardLevels