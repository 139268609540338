import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import { useLoginMutation } from '../../api/authApi'; // Import login mutation
import { useDispatch } from 'react-redux';
import '../../styles/sign.css';
import FormInput from '../FormInput';
import { setAuth, setError } from '../../features/authSlice';

const Signin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [login, { isLoading }] = useLoginMutation();

  const [errorMessage, setErrorMessage] = useState('');
  const [errorMessages, setErrorMessages] = useState([]);

  // Formik for form handling and validation
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Invalid email format')
        .required('Email is required'),
      password: Yup.string()
        .min(6, 'Password must be at least 6 characters')
        .required('Password is required'),
    }),
    onSubmit: async (values) => {
      setErrorMessage('');
      setErrorMessages([]);
      try {
        const response = await login(values).unwrap();
        dispatch(
          setAuth({ user: response?.data?.user, token: response?.data?.token })
        );
        navigate('/dashboard');
      } catch (err) {
        dispatch(setError('Invalid login credentials'));

        setErrorMessage(err?.data?.message?.login);
        setErrorMessages(err?.data?.message);
      }
    },
  });

  return (
    <div className="h-[100vh]">
      <div className="bgSign ">
        <div className="small-container">
          <h2 className="text-center headStyle text-3xl">SIGN BACK IN</h2>

          <form className="container" onSubmit={formik.handleSubmit}>
            <div className="flex justify-center mb-3">
              <div className="w-full md:w-1/3">
                <FormInput
                  placeholder="Email Address"
                  aria-label="Email Address"
                  className={`appearance-none block w-full bg-transparent placeholder-[#DCC23B] hover:bg-transparent focus:bg-transparent border ${
                    (formik.touched.email && formik.errors.email) ||
                    errorMessages?.email
                      ? 'border-red-500 text-red-500'
                      : 'border-[#DCC23B] text-[#DCC23B]'
                  } rounded py-3 px-4 mb-3 leading-tight focus:outline-none `}
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={formik.touched.email && formik.errors.email}
                />
                {formik.touched.email && formik.errors.email && (
                  <p className="text-red-500 text-xs">{formik.errors.email}</p>
                )}
                {errorMessages?.email &&
                  errorMessages.email.map((e) => (
                    <p className="text-red-500 text-xs">{e}</p>
                  ))}
              </div>
            </div>

            <div className="flex justify-center mb-3">
              <div className="w-full md:w-1/3">
                <FormInput
                  placeholder="Password"
                  aria-label="Password"
                  className={`appearance-none block w-full bg-transparent placeholder-[#DCC23B] hover:bg-transparent focus:bg-transparent border ${
                    (formik.touched.password && formik.errors.password) ||
                    errorMessages?.password
                      ? 'border-red-500 text-red-500'
                      : 'border-[#DCC23B] text-[#DCC23B]'
                  } rounded py-3 px-4 leading-tight focus:outline-none `}
                  type="password"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={formik.touched.password && formik.errors.password}
                />
                {formik.touched.password && formik.errors.password && (
                  <p className="text-red-500 text-xs">
                    {formik.errors.password}
                  </p>
                )}
                {errorMessages?.password &&
                  errorMessages.password.map((e) => (
                    <p className="text-red-500 text-xs">{e}</p>
                  ))}
              </div>
            </div>

            <div className="flex justify-center mb-3">
              <div className="w-full md:w-1/3">
                <Link to="/reset" className="hstyle-Signin">
                  forgot password?
                </Link>
              </div>
            </div>

            {errorMessage && (
              <p className="text-red-500 text-xs text-center">{errorMessage}</p>
            )}

            <div className="text-center mt-5">
              <button
                type="submit"
                className="btn btn-primary py-4 px-16 font-semibold tracking-widest"
                disabled={isLoading}
              >
                {isLoading ? 'Signing in...' : 'SIGN IN'}
              </button>
            </div>
          </form>

          <h5 className="text-center hstyle">
            Don’t have an account?{' '}
            <Link to="/signup" className="underline">
              SIGN UP
            </Link>
          </h5>
        </div>
      </div>
    </div>
  );
};

export default Signin;
