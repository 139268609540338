import React from "react";
// import Newsview from '../components/Newsview'


import Signin from "../components/login/Signin";

const SignIn = () => {
  return (
    <>
      <Signin />
     
      {/* <Newsview /> */}
    </>
  );
};

export default SignIn;
