import baseApi from './baseApi';

export const authApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: '/login',
        method: 'POST',
        body: credentials,
      }),
    }),
    signUp: builder.mutation({
      query: (userData) => ({
        url: '/sign_up',
        method: 'POST',
        body: userData,
      }),
    }),
    getProfile: builder.query({
      query: (token) => ({
        url: '/profile',
        method: 'GET',
        headers: {
          token,
        },
      }),
    }),
    updateProfile: builder.mutation({
      query: ({ token, data }) => ({
        url: '/updateprofile',
        method: 'POST',
        body: data,
        headers: {
          token,
        },
      }),
    }),
    resetPassword: builder.mutation({
      query: ({ token, data }) => ({
        url: '/resetpassword',
        method: 'POST',
        body: data,
        headers: {
          token,
        },
      }),
    }),
    forgetPassword: builder.mutation({
      query: ({ token, data }) => ({
        url: '/forgetPassword',
        method: 'POST',
        body: data,
        headers: {
          token,
        },
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useSignUpMutation,
  useGetProfileQuery,
  useUpdateProfileMutation,
  useForgetPasswordMutation,
  useResetPasswordMutation,
} = authApi;
