import React from "react";
import gallery1 from "../assets/gallery1.png";
import gallery2 from "../assets/gallery2.png";
import gallery3 from "../assets/gallery3.png";
import "../styles/Services.css";
import "../styles/Services.css";
import players from "../assets/players.png";
import clock from "../assets/clock.png";
import ghost from "../assets/ghost.png";
const GalleryImages = () => {
  return (
    <div className="container-mode grid grid-cols-2 max-xl:grid-cols-1 gap-6 mt-6 py-14 px-12 relative lg:items-center">
      <div className="gallery-container">
        <img className="gallery-img1" src={gallery1} />
        <img className="gallery-img2" src={gallery2} />
        <img className="gallery-img3" src={gallery3} />
      </div>
      <div className="gallery-content">
        <h5 className="card-title-gallery">Years of catering happiness</h5>
        <p className="card-text-gallery">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud. Lorem ipsum dolor sit amet, consectetur
          adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </p>
        <div className="flex justify-between mt-5">
          <div className="flex items-center mr-5">
            <img src={ghost} className="team-goals-img" alt="..." />
            <p className="team-goals-text card-text">Birthday</p>
          </div>
          <div className="flex items-center mr-5">
            <img src={ghost} className="team-goals-img" alt="..." />
            <p className="team-goals-text card-text">Birthday</p>
          </div>
        </div>
        <div className="flex justify-between mt-5">
          <div className="flex items-center mr-5">
            <img src={ghost} className="team-goals-img" alt="..." />
            <p className="team-goals-text card-text">Birthday</p>
          </div>
          <div className="flex items-center mr-5">
            <img src={ghost} className="team-goals-img" alt="..." />
            <p className="team-goals-text card-text">Birthday</p>
          </div>
        </div>
        <div className="grid  ml-5 mt-5">
          <hr className="break-line ml-4" />
          <div className="flex justify-between mt-2">
            <div className="flex items-center">
              <p className="team-goals-text card-text">Cooperation</p>
            </div>
            <div className="flex items-center">
              <p className="team-goals-text card-text">%</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GalleryImages;
