import React from "react";
import Servicestitle from "./Servicestitle";
import Cardcomponent from "./Cardcomponent";
import Singlecard from "./Singlecard";
import "../styles/Services.css";
import Cardcomponentreverse from "./Cardcomponentreverse";
import Servicesubtitle from "./Servicesubtitle";
import Servicesminititle from "./Servicesminititle";
import "../styles/news.css";
import FormInput from "./FormInput";
import "../styles/form-comment.css";

import GalleryImages from "./GalleryImages";
import InputDropdown from "./InputDropdown";
import LabelForm from "./LabelForm";

const Servicescomponent = ({ label, ...props }) => {
  return (
    <>
      <div className="bgAbout"></div>
      <div>
        <div className="text-center h-[60vh]  mt-44">
          <Servicestitle
            headtitle="UNVEILING UNFORGETTABLE ESCAPADES"
           
          />
        </div>

        <GalleryImages />
        <Servicesubtitle headsubtitle="Enjoy our diverse programs" />
        <Cardcomponent title="Birthdays program" />
        <Cardcomponentreverse title="Trips program" />
        <Servicesubtitle headsubtitle="Escape room News" />
        <Servicesminititle minititle="Upcoming (Worth the wait)" />
        <div className="flex justify-around ">
          <Singlecard />
          <Singlecard />
        </div>
        <Servicesminititle minititle="New releases (Worth the try)" />
        <div className="flex justify-around ">
          <Singlecard />
          <Singlecard />
        </div>
        <Servicesminititle minititle="Popular stories" />
        <div className="flex justify-around ">
          <Singlecard />
          <Singlecard />
        </div>
        <Servicesubtitle headsubtitle="Need more details?" />

        <div className="mt-5">
          <LabelForm />
        </div>
        <div className="text-center my-5">
          <button className="btn btn-primary btn-lg flex justify-center mx-auto px-4 py-2">
            SEND COMMENT
          </button>
        </div>
      </div>
    </>
  );
};

export default Servicescomponent;
