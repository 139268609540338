import { createSlice } from '@reduxjs/toolkit';

const aboutContentSlice = createSlice({
  name: 'aboutContent',
  initialState: {
    list: [],
  },
  reducers: {
    setAboutContent: (state, action) => {
      state.list = action.payload;
    },
  },
});

export const { setAboutContent } = aboutContentSlice.actions;
export default aboutContentSlice.reducer;
