import { baseApi } from '../baseApi';

export const aboutContentApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAboutContent: builder.query({
      query: () => '/contents/about',
    }),
  }),
  overrideExisting: false,
});

export const { useGetAboutContentQuery } = aboutContentApi;
