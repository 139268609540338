import React from 'react';
import pngwing4 from '../../assets/pngwing 4.png';
import location from '../../assets/location.png';
import tv from '../../assets/tv.png';
import { useGetAboutContentQuery } from '../../api/content/aboutContentApi';

const WhyEscape = () => {
  const { data: AboutContent } = useGetAboutContentQuery();
  const whyEscapeRoomSec = AboutContent.find(
    (item) => item.section.name_en === 'Why escape room?'
  );
  const reasons = AboutContent.filter(
    (item) => item.section.name_en === 'reason'
  );

  return (
    <div className="relative">
      <img
        src={pngwing4}
        width="180px"
        alt='wing'
        className="lg:mr-20 rounded-xl absolute -right-10 max-lg:right-0 -top-44 max-lg:-top-32 z-10 max-lg:w-[80px]"
      />
      <div className="bg_why"></div>
      <div className="flex items-end pt-44 pl-44 max-lg:pl-20 mb-56">
        <img
          src={location}
          width="500px"
          alt='location'
          className="lg:mr-3 rounded-xl max-lg:w-[200px] pb-3"
        />
        <p className="text-[#FFF705] text-2xl max-lg:text-lg">Sohag, Egypt</p>
      </div>
      <div className="px-24 max-lg:px-5">
        <div className="grid grid-cols-2 max-xl:grid-cols-1 gap-4 lg:items-center">
          <div>
            <h2 className="text-4xl max-lg:text-2xl font_math font-semibold text-[#EFEFEF]">
              {whyEscapeRoomSec.title_en}
            </h2>
            <p className="text-[#E9E5DD] text-lg my-5">
              {whyEscapeRoomSec.desc_en}
            </p>
            <div className="grid grid-cols-3 max-xl:grid-cols-1 gap-4 lg:items-center">
              {reasons.map((reason, index) => (
                <div
                  key={reason.title_en + index}
                  className="text-[#E8E361] bg-[#0A0A0A] p-3 rounded-3xl text-xl text-center"
                >
                  {reason.title_en}
                </div>
              ))}
            </div>
          </div>
          <div>
            <img src={tv} className="" alt='tv' />
          </div>
        </div>
        <div>
          <div className="flex items-center justify-between text-[#E9E5DD] px-20">
            <p>Entertainment</p>
            <p>%</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyEscape;
