import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../assets/Logo-01 2.png';
import {
  FacebookFilled,
  InstagramFilled,
  TikTokFilled,
} from '@ant-design/icons';
import { useGetContactContentQuery } from '../api/content/contactContentApi';
import { useDispatch, useSelector } from 'react-redux';
import { setContactContent } from '../features/content/contactContentSlice';
import { useGetSocialMediaQuery } from '../api/socialMediaApi';
import { setSocialMedia } from '../features/socialMediaSlice';

const Footer = () => {
  const dispatch = useDispatch();
  const { data: socialMedia } = useGetSocialMediaQuery();
  const { data: contactContent } = useGetContactContentQuery();

  const { email, phoneNumber, address } = useSelector(
    (state) => state.contactContent
  );

  const { facebook, instagram, tiktok } = useSelector(
    (state) => state.socialMedia
  );

  useEffect(() => {
    if (socialMedia) {
      dispatch(setSocialMedia(socialMedia));
    }
  }, [socialMedia, dispatch]);

  useEffect(() => {
    if (contactContent) {
      dispatch(setContactContent(contactContent));
    }
  }, [contactContent, dispatch]);

  return (
    <>
      <div>
        <hr className="h-[5px] w-3/5 max-lg:w-2/3 border border-[#484848] bg-[#484848] mx-auto" />
        <div className="px-44 max-lg:px-5 relative">
          <img
            alt="logo"
            src={Logo}
            className="w-1/2 absolute right-0 bottom-0 z-0"
          />
          <div className="grid grid-cols-4 max-xl:grid-cols-2 gap-8 my-40 max-lg:my-10 relative z-10">
            <div>
              <h3 className="text-[#EEE966] text-2xl max-lg:text-xl font-semibold">
                Company
              </h3>
              <div className="mt-3">
                <Link
                  to="/about"
                  className="text-[#EFEFEF] lg:text-lg block mt-1"
                >
                  About Us
                </Link>
                <Link
                  to="/contact"
                  className="text-[#EFEFEF] lg:text-lg block mt-1"
                >
                  Contact Us
                </Link>
                <Link
                  to="/events"
                  className="text-[#EFEFEF] lg:text-lg block mt-1"
                >
                  Events
                </Link>
                <Link
                  to="/rooms"
                  className="text-[#EFEFEF] lg:text-lg block mt-1"
                >
                  Rooms
                </Link>
              </div>
            </div>
            <div>
              <h3 className="text-[#EEE966] text-2xl max-lg:text-xl font-semibold">
                Contacts
              </h3>
              <div className="mt-3">
                <Link
                  to={`mailto:${email?.title_en}`}
                  className="text-[#EFEFEF] lg:text-lg block mt-1"
                >
                  {email?.title_en}
                </Link>
                <Link
                  to={`tel:${phoneNumber?.title_en}`}
                  className="text-[#EFEFEF] lg:text-lg block mt-1"
                >
                  {phoneNumber?.title_en}
                </Link>
                <p className="text-[#EFEFEF] lg:text-lg block mt-1">
                  {address?.title_en}
                </p>
              </div>
            </div>
            <div>
              <h3 className="text-[#EEE966] text-2xl max-lg:text-xl font-semibold">
                Help
              </h3>
              <div className="mt-3">
                <Link to="/" className="text-[#EFEFEF] lg:text-lg block mt-1">
                  Terms & Conditions
                </Link>
                <Link to="/" className="text-[#EFEFEF] lg:text-lg block mt-1">
                  Privacy Policy
                </Link>
              </div>
            </div>
            <div>
              <h3 className="text-[#EEE966] text-2xl max-lg:text-xl font-semibold ml-7 ">
                Socials
              </h3>
              <div className="mt-3 text-[#EFEFEF]">
                <Link to={facebook?.value} className="mr-4">
                  <FacebookFilled />
                </Link>
                <Link to={instagram?.value} className="mr-4">
                  <InstagramFilled />
                </Link>
                <Link to={tiktok?.value} className="mr-4">
                  <TikTokFilled />
                </Link>
              </div>
            </div>
          </div>
          <div className="text-[#EEE966] text-center relative z-10 pb-5">
            &copy; 2024 Escape Room Sohag | All rights reserved
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
