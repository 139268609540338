import React, { useEffect } from 'react';
import ContactHome from '../components/Contact/ContactHome';
import Servicesubtitle from '../components/Servicesubtitle';
import SwiperComponents from '../components/Contact/SwiperComponents';
import Faq from '../components/Contact/Faq';
import { useGetContactContentQuery } from '../api/content/contactContentApi';
import { useDispatch } from 'react-redux';
import { setContactContent } from '../features/content/contactContentSlice';

const ContactUs = () => {
  const { data: ContactContent } = useGetContactContentQuery();
  const dispatch = useDispatch();

  useEffect(() => {
    if (ContactContent) {
      dispatch(setContactContent(ContactContent));
    }
  }, [ContactContent, dispatch]);


  return (
    <>
      <ContactHome />
      <Servicesubtitle headsubtitle="They made it.. Trust in YOU" />
      <SwiperComponents />
      <Faq />
    </>
  );
};

export default ContactUs;
