import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// export const BaseUrl = 'http://127.0.0.1:8000';
export const BaseUrl = 'https://escaproom-be.dtagdev.com';

export const baseApi = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BaseUrl}/api`,
  }),
  endpoints: () => ({}),
});

export default baseApi;
