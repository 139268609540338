import React from 'react'
import ResetPassword from '../components/login/ResetPassword'

const ForgetPassword = () => {
  return (
    <>
    <ResetPassword/>
    
    </>
  )
}

export default ForgetPassword