import React from "react";
import { Link } from "react-router-dom";
import Room from "./Room";
// import qoute3 from "../assets/qoute3.png";
import { RightOutlined } from "@ant-design/icons";

const Rooms_section = () => {
  return (
    <>
      <div className="rooms-section-container relative">
        <div className="px-24 max-lg:px-5">
          <h2 className="text-4xl max-lg:text-2xl text-center font_math font-bold primary_color mt-20">
            Dare to try our rooms?
          </h2>
          <p className="text-lg text-center text-[#E9E5DD] mt-5">
            Rest assured, they’re all friendly to light hearted... or so we hope
          </p>

          <div className="grid grid-cols-3 max-xl:grid-cols-1 gap-8 my-10 max-lg:my-5">
            <Room activeLevels={10}/>
            <Room activeLevels={6}/>
            <Room activeLevels={10}/>
          </div>

          <div className="flex justify-center mx-auto">
            <Link
              to="/rooms"
              className="sign-up px-2 rounded-3xl border-2 border-[#F4EB19]"
            >
              <button className="btn py-3 px-3 text-[#F4EB19] tracking-wider flex items-center">
                VIEW ALL ROOMS
                <RightOutlined className="ml-3 size-4"/>
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Rooms_section;
