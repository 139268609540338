import React from "react";
import { Link } from "react-router-dom";

const Advertisement = () => {
  return (
    <>
      <div className="px-24 max-lg:px-5 py-20 max-lg:py-10 relative">
        <div className="mx-auto text-center">
          <h5 className="text-[#757575] text-3xl max-lg:text-lg font-semibold	">
            Are your ready?
          </h5>
          <h1 className="mx-auto text-[#E9E5DD] text-5xl max-lg:text-3xl mt-4 font-semibold	">
            Beware of them, they’re
            <span className="text-[#BC3939] block"> everywhere!</span>
          </h1>
        </div>
        <div className="mx-auto flex mt-7">
          <Link
            to="/booknow"
            className="my-btn-1 d-block border-0 px-10 py-3 mx-auto rounded-lg bg-[#D6BA2A] text-[#EFEFEF] text-capitalize tracking-wider font-semibold	"
            href="#"
          >
            BOOK NOW
          </Link>
        </div>
      </div>
    </>
  );
};

export default Advertisement;
