import React from "react";
import explore from "../../assets/explore.png";
import "../../styles/news.css";
import "../../styles/room-view.css";

// import "./RoomViewTab";
const RoomViewHome = () => {
  return (
    <>
      <div className="bg-roomview"></div>
      <div className="content-news  flex flex-col justify-center items-center h-[80vh]">
        <div className="content-news text-center my-24 h-[80vh] ">
          <h1 className="text-3xl ">ASYLUM</h1>
          <p className="text-news  text-xs">Can you escape The Asylum? Guess we’ll find out</p>
        </div>

        <div className="icon-position mx-auto ">
          <div className="explore mx-auto">
            <img src={explore} className="mx-auto img-fluid w-7" />
          </div>
          <p className="text-news-link text-center">EXPLORE</p>{" "}
        </div>
      </div>
    </>
  );
};

export default RoomViewHome;
