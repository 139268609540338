import React from "react";
import { DoubleLeftOutlined, RightOutlined } from "@ant-design/icons";
import "../../styles/rooms.css";
import { Button } from "antd";
import RoomCard from "./RoomCard";
import explore from "../../assets/explore.png";
import Faq from "../../components/Contact/Faq";
const RoomHome = () => {
  return (
    <>
      <div className="bgAbout"></div>

      {/* <div className="h-[100vh]">
        <div className=" content-news  flex flex-col justify-center items-center h-[60vh]">
          <div className="text-center ">
            <h1 className="text-3xl">DARE TO TRY OUR ROOMS?</h1>
            <p className="text-news text-xs">
              Rest assured, they’re all friendly to light hearted... or so we
              hope
            </p>
          </div>

          <div className="icon-position mx-auto ">
            <div className="explore mx-auto">
              <img src={explore} className="mx-auto img-fluid w-7" />
            </div>
            <p className="text-news-link text-center">EXPLORE</p>{" "}
          </div>
        </div>
      </div> */}

   <div className="h-[100vh] flex flex-col justify-between items-center">
  <div className="content-news flex flex-col justify-center items-center h-[60vh]">
    <div className="text-center">
      <h1 className="text-3xl">DARE TO TRY OUR ROOMS?</h1>
      <p className="text-news text-xs">
        Rest assured, they’re all friendly to light hearted... or so we hope
      </p>
    </div>
  </div>

  <div className="icon-position flex flex-col items-center mb-4">
    <div className="explore">
      <img src={explore} className="mx-auto img-fluid w-7" />
    </div>
    <p className="text-news-link text-center">EXPLORE</p>
  </div>
</div>


    </>
  );
};

export default RoomHome;
