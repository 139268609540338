import React from "react";
// import Services from './pages/Services'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import SignIn from "./pages/SignIn";

import Home from "./pages/Home";
import About from "./pages/About";
import Signup from "./pages/Signup";
import ForgetPassword from "./pages/ForgetPassword";
import NewsView from "./pages/NewsView";
import ContactUs from "./pages/ContactUs";
import Services from "./pages/Services"
import Rooms from "./pages/Rooms";
import Layout from "./components/Layout";
import RoomView from "./pages/RoomView";
import Booking from "./pages/Booking";
import BookingPay from "./pages/BookingPay";
const App = () => {
  return (
    <Router>
      <Layout>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/reset" element={<ForgetPassword />} />
        <Route path="/news" element={<NewsView />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/rooms" element={<Rooms />} />
        <Route path="/roomsview" element={<RoomView />} />
        <Route path="/booknow" element={<Booking />} />
        <Route path="/pay" element={<BookingPay />} />
      </Routes>
      </Layout>
    </Router>
  );
};

export default App;
