import React from 'react';
import Trapped from './Home/Trapped';
import qoute3 from '../assets/qoute3.png';
import qeustion from '../assets/qeustion.png';
import ghost from '../assets/ghost.png';
import vid from '../assets/vid.png';
import video from '../assets/vid.mp4';
import '../styles/Explain.css';
import { useGetHomeContentQuery } from '../api/content/homeContentApi';

const Explain = () => {
  const { data: homeContent } = useGetHomeContentQuery();
  const quote = homeContent.find((item) => item.section.name_en === 'Quote');
  const expect = homeContent.find((item) => item.section.name_en === 'Expect');
  const points = homeContent.filter((item) => item.section.name_en === 'point');

  return (
    <>
      <div className="explain-container h-full relative">
        <div className="bg_explain Explain relative">
          <div className="px-24 max-lg:px-5">
            <h2 className="text-4xl max-lg:text-2xl font_math font-bold primary_color mt-32 max-lg:mt-10">
              Trapped in terror?
              <br /> Here’s how it goes
            </h2>
            <p className="text-lg text-[#E9E5DD] lg:w-3/4 mt-5">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud.
            </p>

            <Trapped />

            <div className="relative mt-20">
              <h3 className="text-[#F1EFE6] text-3xl max-lg:text-lg text-center">
                {quote.title_en}
              </h3>
              <img
                src={qoute3}
                width="40px"
                className="absolute right-64 max-lg:right-0 -top-5 max-lg:w-[20px]"
              />
            </div>

            <img
              src={qeustion}
              width="80px"
              className="absolute left-0 max-lg:w-[40px]"
            />

            <div className="grid grid-cols-2 max-xl:grid-cols-1 gap-8 my-14 lg:items-center">
              <div>
                <video
                  controls
                  width="500px"
                  poster={vid}
                  className="flex mx-auto rounded-2xl bg-black w-[500px] h-[400px] max-lg:w-full"
                >
                  <source src={video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>

              <div>
                <h2 className="primary_color text-4xl max-lg:text-2xl font-semibold font_math">
                  {expect.title_en}
                </h2>
                <p className="second_color mt-4 mb-7">{expect.desc_en}</p>
                <div className="grid grid-cols-2 max-xl:grid-cols-2 gap-4 second_color ml-16 max-lg:ml-8">
                  {points.map((point) => (
                    <div key={point.title_en} className="flex items-center">
                      <img src={ghost} width="20px" className="" />
                      <p className="ml-3">{point.title_en}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <p className="text-center second_color w-2/3 max-lg:w-full mx-auto">
              {quote.desc_en}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Explain;
