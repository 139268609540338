import React from "react";
import "../styles/Services.css";
const Servicesminititle = ({minititle}) => {
  return (
    <div className="container-services-subtitle">
      <div className="overlay">
      <div className="content-services-minititle ">
        <h4>{minititle}</h4>
      </div>
      </div>
    </div>
  );
};

export default Servicesminititle;