import React from "react";

const Terror = ({ number, title, desc, image }) => {
  return (
    <>
      <div className="mt-20 max-lg:mt-5 relative h-full">
        <div className="grid grid-cols-3 max-xl:grid-cols-1 gap-4">
          <div className="col-span-2">
            <div className="relative">
              <p className="absolute -left-7 max-lg:-left-2 -top-14 max-lg:-top-5  z-0 text-8xl max-lg:text-5xl text-[#fdf3005c] font-extrabold font-serif	">
                {number}
              </p>
              <h2 className="text-3xl max-lg:text-3xl z-10 primary_color">{title}</h2>
            </div>
            <p className="text-lg mt-5 ml-5 mr-14 max-lg:mr-0 second_color">{desc}</p>
          </div>
          <div>
            <img src={image} className="rounded-3xl max-lg:mx-2" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Terror;
