import React from 'react';
import '../../styles/Services.css';
import players from '../../assets/players.png';
import clock from '../../assets/clock.png';
import sketelon from '../../assets/skeleton.png';
import { Rate, Button } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { BaseUrl } from '../../api/baseApi';

const levelMap = {
  'Very Easy': 3,
  Easy: 6,
  Medium: 9,
  Hard: 12,
  'Very hard': 15,
};

const RoomCard = ({ room }) => {
  console.log('🚀 ~ RoomCard ~ room:', room);
  const totalLevels = 15;
  const activeLevels = levelMap[room.level.name_en] || 1;

  // Generate the divs dynamically
  const levelDivs = Array.from({ length: totalLevels }, (_, index) => {
    return (
      <div
        key={index}
        className={`h-5 w-2 rounded-sm mr-2 ${
          index < activeLevels ? 'bg-[#D6B715]' : 'bg-[#333333]'
        }`}
      ></div>
    );
  });

  return (
    <div className="hover:bg-[#0A0A0A] mx-6 ">
      <div className=" mb-3  room  ">
        <div className="grid grid-cols-8 gap-4 mx-12  py-10 px-8  ">
          <div className="col-span-3">
            <img
              src={`${BaseUrl}${room.image}`}
              className="w-full h-auto rounded-l-lg text-[#fff]"
              alt={room.name_en}
            />
          </div>
          <div className="col-span-5">
            <div className="card-body pl-8 mt-8">
              <h5 className="card-title text-left">{room.name_en}</h5>
              <div>
                <Rate
                  allowHalf
                  defaultValue={5}
                  className="custom-rate  flex  text-center"
                />
              </div>
              <p className="card-text text-left">
                {room.desc_en.slice(0, 470)}...
              </p>
              <div>
                <div className="flex justify-between mt-10">
                  <div className="flex items-center ">
                    <img src={players} className="team-goals-img" alt="..." />{' '}
                    <p className="team-goals-text card-text">
                      {room.min_players}-{room.max_players} players
                    </p>
                  </div>
                  <div className="flex items-center">
                    <img src={clock} className="team-goals-img" alt="..." />{' '}
                    <p className="team-goals-text card-text">
                      {room.time_in_minutes} mins
                    </p>
                  </div>
                  <div className="flex items-center">
                    <img src={sketelon} className="team-goals-img" alt="..." />{' '}
                    <p className="team-goals-text card-text">
                      {room?.type?.name_en}
                    </p>
                  </div>
                </div>
              </div>

              <div className="ml-6 mt-2 max-lg:ml-3 primary_color">
                <p>Difficulty level</p>
                <div className="flex items-center mt-1">
                  <div className="flex items-center">{levelDivs}</div>
                  <p className="text-sm">{room?.level?.name_en}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-8 flex justify-center w-full mt-10">
            <Link to="/booknow">
              <Button className="btn btn-lg border-transparent room-btn mr-8 px-8">
                BOOK ROOM
              </Button>
            </Link>
            <Link to="/roomsview">
              <Button className="btn btn-lg  room-btn-outline border-2 border-[#C7A807] font-semibold px-8 inline-flex items-center">
                VIEW MORE
                <RightOutlined />
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoomCard;
