import React from 'react'
import OurVision from '../components/OurVision';
import OurMisson from '../components/OurMisson';
import WhyUS from '../components/WhyUS';
import Faq from '../components/Contact/Faq';
import Reviews from '../components/Reviews';
import Advertisement from '../components/Advertisement';
import "../styles/about.css";
import "../styles/contact.css";
import MainAbout from '../components/About/MainAbout';
import SwiperComponents from '../components/Contact/SwiperComponents';
import WhyEscape from '../components/About/WhyEscape';
import { useGetAboutContentQuery } from '../api/content/aboutContentApi';

const About = () => {
    const { data: AboutContent, isLoading, isError } = useGetAboutContentQuery();


    if (isLoading) {
      return <div className="text-center fs-2">Loading...</div>;
    }

    if (isError || !AboutContent || !AboutContent.length) {
      return (
        <div className="text-center text-red-600 fs-2">
          Something went error!
        </div>
      );
    } else
      return (
        <>
          <MainAbout />
          <SwiperComponents />
          <WhyEscape />
          {/* <OurVision /> */}
          {/* <OurMisson /> */}
          {/* <Reviews /> */}
          {/* <WhyUS /> */}

          <Faq />
          <Advertisement />
        </>
      );
}

export default About
