import React, { useState } from 'react';
import '../../styles/sign.css';
import FormInput from '../FormInput';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSignUpMutation } from '../../api/authApi';
import { useDispatch } from 'react-redux';
import Spinner from '../Loading/LoadingSpinner'; // Import your spinner component
import { setAuth } from '../../features/authSlice';
import { phoneRegExp } from '../../utils/regex';

const SignUp = () => {
  const [signUp, { isLoading }] = useSignUpMutation();
  const [errorMessage, setErrorMessage] = useState('');
  const [errorMessages, setErrorMessages] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate(); // For redirection after successful signup

  const formik = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
      password: '',
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required('First name is required'),
      lastname: Yup.string().required('Last name is required'),
      email: Yup.string()
        .email('Invalid email format')
        .required('Email is required'),
      phone: Yup.string()
        .matches(phoneRegExp, 'Invalid phone format')
        .required('Phone is required'),
      password: Yup.string()
        .min(6, 'Password must be at least 6 characters')
        .required('Password is required'),
    }),
    onSubmit: async (values) => {
      setErrorMessage(''); // Reset error message
      setErrorMessages([]);

      try {
        const response = await signUp(values).unwrap();
        console.log('🚀 ~ onSubmit: ~ response:', response);
        // Dispatch the user and token to the Redux store
        dispatch(
          setAuth({ user: response?.data?.user, token: response?.data?.token })
        );
        // Redirect to dashboard or another page
        navigate('/dashboard');
      } catch (error) {
        setErrorMessage('Something went wrong');
        setErrorMessages(error?.data?.message);
      }
    },
  });

  return (
    <div className="h-[100vh]">
      <div className="bgSign">
        <div className="container mx-auto p-4">
          <h2 className="text-center headStyle">CREATE AN ACCOUNT</h2>

          <form
            className="w-full max-w-2xl mx-auto container"
            onSubmit={formik.handleSubmit}
          >
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full md:w-1/2 px-3 md:mb-0">
                <FormInput
                  name="firstname"
                  placeholder="First name"
                  className={`appearance-none block w-full bg-transparent placeholder-[#DCC23B] hover:bg-transparent focus:bg-transparent border ${
                    (formik.touched.firstname && formik.errors.firstname) ||
                    errorMessages?.firstname
                      ? 'border-red-500 text-red-500'
                      : 'border-[#DCC23B] text-[#DCC23B]'
                  } rounded py-3 px-4 mb-3 leading-tight focus:outline-none `}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.firstname}
                />
                {formik.touched.firstname && formik.errors.firstname && (
                  <p className="text-red-500 text-xs">
                    {formik.errors.firstname}
                  </p>
                )}
                {errorMessages?.firstname &&
                  errorMessages.firstname.map((e) => (
                    <p className="text-red-500 text-xs">{e}</p>
                  ))}
              </div>
              <div className="w-full md:w-1/2 px-3">
                <FormInput
                  name="lastname"
                  placeholder="Last name"
                  className={`appearance-none block w-full bg-transparent placeholder-[#DCC23B] hover:bg-transparent focus:bg-transparent border ${
                    (formik.touched.lastname && formik.errors.lastname) ||
                    errorMessages?.lastname
                      ? 'border-red-500 text-red-500'
                      : 'border-[#DCC23B] text-[#DCC23B]'
                  } rounded py-3 px-4 leading-tight focus:outline-none `}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.lastname}
                />
                {formik.touched.lastname && formik.errors.lastname && (
                  <p className="text-red-500 text-xs">
                    {formik.errors.lastname}
                  </p>
                )}
                {errorMessages?.lastname &&
                  errorMessages.lastname.map((e) => (
                    <p className="text-red-500 text-xs">{e}</p>
                  ))}
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3">
                <FormInput
                  name="email"
                  placeholder="Email Address"
                  className={`appearance-none block w-full bg-transparent placeholder-[#DCC23B] hover:bg-transparent focus:bg-transparent border ${
                    (formik.touched.email && formik.errors.email) ||
                    errorMessages?.email
                      ? 'border-red-500 text-red-500'
                      : 'border-[#DCC23B] text-[#DCC23B]'
                  } rounded py-3 px-4 leading-tight focus:outline-none `}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email && (
                  <p className="text-red-500 text-xs">{formik.errors.email}</p>
                )}
                {errorMessages?.email &&
                  errorMessages.email.map((e) => (
                    <p className="text-red-500 text-xs">{e}</p>
                  ))}
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3">
                <FormInput
                  name="phone"
                  placeholder="Phone number"
                  className={`appearance-none block w-full bg-transparent placeholder-[#DCC23B] hover:bg-transparent focus:bg-transparent border ${
                    (formik.touched.phone && formik.errors.phone) ||
                    errorMessages?.phone
                      ? 'border-red-500 text-red-500'
                      : 'border-[#DCC23B] text-[#DCC23B]'
                  } rounded py-3 px-4 leading-tight focus:outline-none `}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phone}
                />
                {formik.touched.phone && formik.errors.phone && (
                  <p className="text-red-500 text-xs">{formik.errors.phone}</p>
                )}
                {errorMessages?.phone &&
                  errorMessages.phone.map((e) => (
                    <p className="text-red-500 text-xs">{e}</p>
                  ))}
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3">
                <FormInput
                  name="password"
                  type="password"
                  placeholder="Password"
                  className={`appearance-none block w-full bg-transparent placeholder-[#DCC23B] hover:bg-transparent focus:bg-transparent border ${
                    (formik.touched.password && formik.errors.password) ||
                    errorMessages?.password
                      ? 'border-red-500 text-red-500'
                      : 'border-[#DCC23B] text-[#DCC23B]'
                  } rounded py-3 px-4 leading-tight focus:outline-none `}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                />
                {formik.touched.password && formik.errors.password && (
                  <p className="text-red-500 text-xs">
                    {formik.errors.password}
                  </p>
                )}
                {errorMessages?.password &&
                  errorMessages.password.map((e) => (
                    <p className="text-red-500 text-xs">{e}</p>
                  ))}
              </div>
            </div>
            {errorMessage && (
              <p className="text-red-500 text-xs text-center">{errorMessage}</p>
            )}
            {/* Show error message */}
            <div className="text-center mt-6">
              <button
                className="btn btn-primary py-4 px-16 font-semibold tracking-widest relative"
                type="submit"
                disabled={isLoading} // Disable button while loading
              >
                {isLoading ? <Spinner /> : 'SIGN UP'}{' '}
                {/* Show spinner or text */}
              </button>
            </div>
          </form>

          <h5 className="text-center text-gray-700 mt-4 hstyle">
            Already have an account?{' '}
            <Link to="/signin" className="underline">
              SIGN IN
            </Link>
          </h5>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
