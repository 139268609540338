import React, { useState } from "react";
import players from "../../assets/Mask group.png";
import Ellipse from "../../assets/Ellipse 34.png";

const Bundle = ({ playerNumber, price, selected, onClick }) => {
  return (
    <div
      className={`bg-[#0A0A0A] text-center relative h-[200px] cursor-pointer ${
        selected ? "border-4 border-yellow-500" : ""
      }`}
      onClick={onClick}
    >
      <div>
        <img
          src={Ellipse}
          width="150px"
          className="max-lg:w-[30px] mx-auto absolute z-0 top-0 right-0"
        />
        <p className="text-end text-xl font-bold italic relative z-10 pt-3 pr-8">
          {playerNumber} players
        </p>
      </div>
      <img src={players} width="50px" className="max-lg:w-[30px] mx-auto mt-2" />
      <h3 className="text-[#DAD7C1] text-4xl max-lg:text-2xl font_math font-semibold text-center mt-4">
        {price} EGP / player
      </h3>
    </div>
  );
};

export default Bundle;