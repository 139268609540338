import React from 'react';
import { Collapse } from 'antd';
import '../../styles/Faq.css';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { useGetAboutContentQuery } from '../../api/content/aboutContentApi';
import { useGetFaqsQuery } from '../../api/faqsApi';

const Faq = () => {
  const { data: AboutContent } = useGetAboutContentQuery();
  const faqSec = AboutContent?.find(
    (item) => item.section.name_en === 'Faq Header'
  );
  const { data: faqList, isError, isLoading } = useGetFaqsQuery();

  if (isLoading) {
    return <div className="text-center fs-2">Loading...</div>;
  }

  if (isError || !faqList || !faqList.length) {
    return (
      <div className="text-center text-red-600 fs-2">Something went error!</div>
    );
  }

  const items = faqList?.length && faqList.map((item, index) => {
    return {
      key: `${index}`,
      label: item.q_en,
      children: (
        <h4 className="text-lg max-lg:text-base pl-6 pr-10 max-lg:pr-6">
          {item.a_en}
        </h4>
      ),
    };
  });

  
  const customExpandIcon = ({ isActive }) =>
    isActive ? (
      <MinusOutlined className="plus-icon mt-4" />
    ) : (
      <PlusOutlined className="plus-icon mt-4" />
    );
    
  return (
    <div
      className="px-24 mx-12 my-16 bg-[#121212] max-lg:px-5 "
      style={{
        fontWeight: '700',
        fontFamily: 'Comfortaa',
      }}
    >
      <h2 className="text-4xl text-center faq-title">{faqSec?.title_en}</h2>
      <p className="text-lg text-center faq-subtitle my-6 mx-44">
        {faqSec?.desc_en}
      </p>
      <div className="flex justify-center mx-auto">
        <Collapse
          defaultActiveKey={['1']}
          inlineCollapsed={false}
          items={items}
          expandIcon={customExpandIcon}
          expandIconPosition="end"
          bordered={false}
          style={{
            fontWeight: '400',
            fontFamily: 'Comfortaa',
          }}
          className="text-2xl faq-text text-blue-600  max-lg:text-xl w-3/4 max-lg:w-full"
        />
      </div>
    </div>
  );
};

export default Faq;
