import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation"; // Import Swiper navigation CSS
import swiper1 from "../../assets/swiper1.png";
import swiper2 from "../../assets/swiper2.png";
import swiper3 from "../../assets/swiper3.png";
import "../../styles/room-view.css";
// Import Swiper core and required modules
import { Navigation, Pagination } from "swiper/modules";
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons"; // Import Ant Design icons

const RoomSwiper = () => {
  return (
    <Swiper
      grabCursor={true}
      centeredSlides={true}
      slidesPerView={3}
      loop={true}
      spaceBetween={132}
      pagination={{ clickable: true }}
      navigation={{
        prevEl: ".swiper-button-prev",
        nextEl: ".swiper-button-next",
      }}
      breakpoints={{
        640: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 2,
        },
        1024: {
          slidesPerView: 3,
        },
      }}
      modules={[Navigation, Pagination]}
      className=""
    >
      <SwiperSlide>
        <img src={swiper1} alt="Slide 1" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={swiper2} alt="Slide 2" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={swiper3} alt="Slide 3" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={swiper1} alt="Slide 4" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={swiper2} alt="Slide 5" />
      </SwiperSlide>
      {/* Add more slides as needed */}

      {/* Navigation Arrows */}
      <div className="swiper-button-prev">
        <span class="material-symbols-outlined">west</span>
      </div>
      <div className="swiper-button-next">
        <span class="material-symbols-outlined">east</span>
      </div>
    </Swiper>
  );
};

export default RoomSwiper;
