import React from 'react';
import Main from '../components/Main';
import Explain2 from '../components/Explain2';
import Rooms from '../components/Rooms';
import Services from '../components/Services';
import Reviews from '../components/Reviews';
import Advertisement from '../components/Advertisement';
import '../styles/main.css';
import '../styles/home.css';
import Rooms_section from '../components/Home/Rooms_section';
import OurServices from '../components/Home/OurServices';
import SwiperComponents from '../components/Contact/SwiperComponents';
import Servicesubtitle from '../components/Servicesubtitle';
import { useGetHomeContentQuery } from '../api/content/homeContentApi';

const Home = () => {
  const { data: HomeContent, isLoading, isError } = useGetHomeContentQuery();

  if (isLoading) {
    return <div className="text-center fs-2">Loading...</div>;
  }

  if (isError || !HomeContent || !HomeContent.length) {
    return (
      <div className="text-center text-red-600 fs-2">Something went error!</div>
    );
  }
  
  return (
    <div className="home">
      <Main />
      <Explain2 />
      <Rooms_section />
      {/* <Rooms /> */}
      <OurServices />
      {/* <Services /> */}

      <div className="pt-10">
        <Servicesubtitle headsubtitle="They made it.. Trust in YOU" />
        <SwiperComponents />
      </div>
      {/* <Reviews /> */}

      <Advertisement />
    </div>
  );
};

export default Home;
