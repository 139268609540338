import { combineReducers } from 'redux';
import homeContentReducer from '../features/content/homeContentSlice';
import aboutContentReducer from '../features/content/aboutContentSlice';
import contactContentReducer from '../features/content/contactContentSlice';
import socialMediaReducer from '../features/socialMediaSlice';
import { baseApi } from '../api/baseApi';
import faqsReducer from '../features/faqsSlice';
import roomsReducer from '../features/roomsSlice';
import authReducer from '../features/authSlice';

const rootReducer = combineReducers({
  homeContent: homeContentReducer,
  aboutContent: aboutContentReducer,
  contactContent: contactContentReducer,
  socialMedia: socialMediaReducer,
  faqs: faqsReducer,
  rooms: roomsReducer,
  auth: authReducer,
  [baseApi.reducerPath]: baseApi.reducer,
});

export default rootReducer;
