import { createSlice } from '@reduxjs/toolkit';

const contactContentSlice = createSlice({
  name: 'contactContent',
  initialState: {
    list: [],
    header: null,
    email: null,
    phoneNumber: null,
    address: null,
  },
  reducers: {
    setContactContent: (state, action) => {
      state.list = action.payload;
      console.log("🚀 ~ action.payload:", action.payload)

      // Set the specific fields
      state.header = action.payload.find(
        (item) => item.section.name_en === 'header'
      );
      state.email = action.payload.find(
        (item) => item.section.name_en === 'Email'
      );
      state.phoneNumber = action.payload.find(
        (item) => item.section.name_en === 'Phone number'
      );
      state.address = action.payload.find(
        (item) => item.section.name_en === 'address'
      );
    },
  },
});

export const { setContactContent } = contactContentSlice.actions;
export default contactContentSlice.reducer;
