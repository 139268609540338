import { baseApi } from './baseApi';

export const faqsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getFaqs: builder.query({
      query: () => '/faqs',
    }),
  }),
  overrideExisting: false,
});

export const { useGetFaqsQuery } = faqsApi;
