import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSendContactFormMutation } from '../api/contactApi'; // Import the mutation hook
import FormInput from './FormInput'; // Assuming you have a FormInput component
import '../styles/news.css';
import '../styles/form-comment.css';
import { useSelector } from 'react-redux';
import { subjectRegExp } from '../utils/regex';

const LabelForm = () => {
  const [errorMessages, setErrorMessages] = useState([]);
  const [sendContactForm] = useSendContactFormMutation(); // Destructure the mutation function
  const { user } = useSelector((state) => state.auth);

  // Formik for form handling and validation
  const formik = useFormik({
    initialValues: {
      firstname: user?.firstname ?? '',
      lastname: user?.lastname ?? '',
      email: user?.email ?? '',
      phone: user?.phone ?? '',
      subject: user?.subject ?? '',
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required('First name is required'),
      lastname: Yup.string().required('Last name is required'),
      email: Yup.string()
        .email('Invalid email format')
        .required('Email is required'),
      phone: Yup.string().required('Phone number is required'),
      subject: Yup.string()
        .matches(subjectRegExp, 'Subject must be in format Subject / Comments / Questions')
        .required('Subject is required'),
    }),
    onSubmit: async (values) => {
      setErrorMessages([]);
      try {
        await sendContactForm(values).unwrap();
        alert('Message sent successfully!');
      } catch (err) {
        setErrorMessages(err?.data?.message || 'Something went wrong!');
      }
    },
  });

  return (
    <form className="container md:w-3/4 mx-auto" onSubmit={formik.handleSubmit}>
      <div className="flex flex-wrap justify-center mb-3">
        {/* First Name Input */}
        <div className="w-full md:w-1/2 px-2 mb-3 md:mb-0">
          <FormInput
            placeholder="First Name"
            aria-label="First Name"
            className={`appearance-none block w-full bg-transparent placeholder-[#DCC23B] hover:bg-transparent focus:bg-transparent border ${
              formik.touched.firstname && formik.errors.firstname
                ? 'border-red-500 text-red-500'
                : 'border-[#DCC23B] text-[#DCC23B]'
            } rounded py-3 px-4 mb-3 leading-tight focus:outline-none`}
            name="firstname"
            value={formik.values.firstname}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.firstname && formik.errors.firstname && (
            <p className="text-red-500 text-xs">{formik.errors.firstname}</p>
          )}
        </div>

        {/* Last Name Input */}
        <div className="w-full md:w-1/2 px-2">
          <FormInput
            placeholder="Last Name"
            aria-label="Last Name"
            className={`appearance-none block w-full bg-transparent placeholder-[#DCC23B] hover:bg-transparent focus:bg-transparent border ${
              formik.touched.lastname && formik.errors.lastname
                ? 'border-red-500 text-red-500'
                : 'border-[#DCC23B] text-[#DCC23B]'
            } rounded py-3 px-4 mb-3 leading-tight focus:outline-none`}
            name="lastname"
            value={formik.values.lastname}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.lastname && formik.errors.lastname && (
            <p className="text-red-500 text-xs">{formik.errors.lastname}</p>
          )}
        </div>
      </div>

      <div className="flex flex-wrap justify-center mb-3">
        {/* Email Address Input */}
        <div className="w-full md:w-1/2 px-2 mb-3 md:mb-0">
          <FormInput
            placeholder="Email Address"
            aria-label="Email Address"
            className={`appearance-none block w-full bg-transparent placeholder-[#DCC23B] hover:bg-transparent focus:bg-transparent border ${
              formik.touched.email && formik.errors.email
                ? 'border-red-500 text-red-500'
                : 'border-[#DCC23B] text-[#DCC23B]'
            } rounded py-3 px-4 mb-3 leading-tight focus:outline-none`}
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.email && formik.errors.email && (
            <p className="text-red-500 text-xs">{formik.errors.email}</p>
          )}
        </div>

        {/* Phone Number Input */}
        <div className="w-full md:w-1/2 px-2">
          <FormInput
            placeholder="Phone Number"
            aria-label="Phone Number"
            className={`appearance-none block w-full bg-transparent placeholder-[#DCC23B] hover:bg-transparent focus:bg-transparent border ${
              formik.touched.phone && formik.errors.phone
                ? 'border-red-500 text-red-500'
                : 'border-[#DCC23B] text-[#DCC23B]'
            } rounded py-3 px-4 mb-3 leading-tight focus:outline-none`}
            name="phone"
            value={formik.values.phone}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.phone && formik.errors.phone && (
            <p className="text-red-500 text-xs">{formik.errors.phone}</p>
          )}
        </div>
      </div>

      <div className="flex justify-center mb-3">
        {/* Subject/Comments/Questions Input */}
        <div className="w-full px-2">
          <FormInput
            placeholder="Subject / Comments / Questions"
            aria-label="Subject / Comments / Questions"
            className={`appearance-none block w-full bg-transparent placeholder-[#DCC23B] hover:bg-transparent focus:bg-transparent border ${
              formik.touched.subject && formik.errors.subject
                ? 'border-red-500 text-red-500'
                : 'border-[#DCC23B] text-[#DCC23B]'
            } rounded py-3 px-4 mb-3 leading-tight focus:outline-none`}
            name="subject"
            value={formik.values.subject}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.subject && formik.errors.subject && (
            <p className="text-red-500 text-xs">{formik.errors.subject}</p>
          )}
        </div>
      </div>

      <div className="text-center">
        <button type="submit" className="btn btn-lg local py-4 px-16">
          SEND MESSAGE
        </button>
      </div>
    </form>
  );
};

export default LabelForm;
