import React from 'react';
import ghost from '../../assets/ghost.png';
import Rectangle from '../../assets/Rectangle 19.png';
import GroupAbout from '../../assets/GroupAbout.png';
import pngwing from '../../assets/pngwing 1.png';
import { useGetAboutContentQuery } from '../../api/content/aboutContentApi';

const MainAbout = () => {
  const { data: AboutContent } = useGetAboutContentQuery();

  const header = AboutContent.find((item) => item.section.name_en === 'header');
  const ourMission = AboutContent.find((item) => item.section.name_en === 'Our Mission');
  const points = AboutContent.filter((item) => item.section.name_en === 'point');
  const expSec = AboutContent.find((item) => item.section.name_en === 'expirience');
  const words = expSec.title_en.split(' ');
  const expTitlelastWord = words.pop();
  const restOfExpTitle = words.join(' ');


  return (
    <>
      <div className="bgAbout"></div>
      <div className="px-24 max-lg:px-5 header mb-0" id="hero">
        <div className="text-center h-[60vh]">
          <h1 className="text-5xl max-lg:text-4xl mx-auto px-7 text-[#EFEFEF] font_math mt-44">
            DELVE INTO THE TWISTED WORLD <br /> OF OUR ESCAPE ROOMS
          </h1>
        </div>

        <div>
          <h2 className="text-4xl max-lg:text-2xl font_math font-semibold text-center px-7 text-[#EFEFEF]">
            {header.title_en}
          </h2>
          <p className="text-[#E9E5DD] text-lg text-center my-3">
            {header.desc_en}
          </p>
        </div>

        <div className="grid grid-cols-3 max-xl:grid-cols-1 gap-6 mt-6 text-[#E9E5DD] text-lg">
          {points.map((point, index) => (
            <div
              key={point.title_en + index}
              className="flex items-center justify-center "
            >
              <img src={ghost} width="20px" className="mr-5" />
              <p>{point.title_en}</p>
            </div>
          ))}
        </div>

        <div className="grid grid-cols-2 max-xl:grid-cols-1 gap-6 mt-6 py-14 relative lg:items-center">
          <div className="flex justify-end">
            <img
              src={GroupAbout}
              width="450px"
              className="rounded-xl absolute -left-24 max-lg:-left-9 -top-32 max-lg:-top-20 z-0 max-lg:w-[270px]"
            />
            <img
              src={Rectangle}
              width="380px"
              className="lg:mr-20 rounded-xl relative z-10 max-lg:w-[230px]"
            />
          </div>
          <div>
            <h2 className="text-4xl max-lg:text-2xl font_math font-semibold text-[#EFEFEF]">
              {ourMission.title_en}
            </h2>
            <p className="text-[#E9E5DD] text-lg my-3">{ourMission.desc_en}</p>
          </div>
        </div>

        <div className="relative">
          <div className="flex items-start">
            <div className="text-[#F4EB19] border-4 border-[#F4EB19] p-7 max-lg:p-4 rounded-full font-bold text-4xl max-lg:text-2xl">
              3+
            </div>
            <div>
              <h2 className="text-5xl max-lg:text-2xl font_math font-bold text-[#EFEFEF] ml-5">
                {restOfExpTitle}
                <br /> {expTitlelastWord}
              </h2>
              <p className="text-[#E9E5DD] text-lg my-3 ml-5 lg:mr-20">
                {expSec.desc_en}
              </p>
            </div>
            <img
              src={pngwing}
              width="200px"
              className="absolute -right-24 max-lg:-right-5 -top-20 max-lg:w-[100px]"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MainAbout;
