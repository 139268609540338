import React from "react";
import { Link } from "react-router-dom";
import qm2 from "../../assets/qm2.png";
import pngwing5 from "../../assets/pngwing5.png";

import { RightOutlined } from "@ant-design/icons";
import Service from "./Service";

const OurServices = () => {
  return (
    <>
      <div className="rooms-section-container relative">
        <img src={qm2} width="130px" className="absolute right-0 -top-14 max-lg:w-[70px]" />
        <div className="px-24 max-lg:px-5">
          <h2 className="text-4xl max-lg:text-2xl font_math font-bold primary_color mt-20">
            Check our services
          </h2>
          <p className="text-lg text-[#E9E5DD] mt-5 w-3/4 max-lg:w-full">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud.
          </p>

          <div className="grid grid-cols-3 max-xl:grid-cols-1 gap-8 my-10 max-lg:mt-5 relative z-10">
            <Service />
            <Service />
            <Service />
          </div>

          <img src={pngwing5} width="280px" className="absolute left-5 max-lg:left-0 -bottom-0 max-lg:bottom-10 z-0 max-lg:w-[100px]" />

          <div className="flex justify-center mx-auto mb-10">
            <Link
              to="/services"
              className="sign-up px-2 rounded-3xl border-2 border-[#F4EB19]"
            >
              <button className="btn py-3 px-5 text-[#F4EB19] tracking-wider flex items-center">
                VIEW MORE
                <RightOutlined className="ml-10 size-4" />
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurServices;
