import { baseApi } from '../baseApi';

export const contactContentApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getContactContent: builder.query({
      query: () => '/contents/contact',
    }),
  }),
  overrideExisting: false,
});

export const { useGetContactContentQuery } = contactContentApi;
