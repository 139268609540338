import React from 'react'
import arrows from "../assets/explore.png";
import { Link } from 'react-router-dom';
import { useGetHomeContentQuery } from '../api/content/homeContentApi';

const Main = () => {
    const { data: homeContent, error, isLoading } = useGetHomeContentQuery();
    const secondSec = homeContent.find(item=>item.section.name_en === 'header')

    return (
      <>
        <div className="px-24 max-lg:px-5 header mb-0" id="hero">
          <div className="content text-center">
            <h1 className="text-6xl max-lg:text-4xl mx-auto lg:w-1/2 text-[#EFEFEF]">
              THINK YOU CAN OUTRUN THEM?
            </h1>
            <p className="text-3xl max-lg:text-lg mt-3 text-[#C7C7C7]">
              Come and Try
            </p>
            <div className="explore mx-auto mb-5 mt-5 py-3">
              <img
                src={arrows}
                alt="explore"
                className="img-fluid mx-auto"
                width="25px"
              />
            </div>
            <Link to="/booknow" className="book mx-auto mb-5">
              <button
                type="button"
                className="btn text-xl max-lg:text-lg px-10 max-lg:px-5 py-3 max-lg:py-3 text-center text-capitalize tracking-wider"
              >
                Book Now
              </button>
            </Link>
          </div>

          <button className="to-the-top" href="#hero">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-arrow-up"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5"
              />
            </svg>
          </button>

          <div className="container mt-24 max-lg:mt-14 pt-3 what-are-esc-room text-center">
            <h2 className="text-4xl max-lg:text-2xl font_math">
              {secondSec.title_en}
            </h2>
            <p className="text-[#E9E5DD] w-4/5 max-lg:w-full text-center mx-auto mt-5">
              {secondSec.desc_en}
            </p>
          </div>
        </div>
      </>
    );
}

export default Main
