import React from "react";
import cardpicture from "../assets/cardpicture.png";
import "../styles/Services.css";
import players from "../assets/players.png";
import clock from "../assets/clock.png";
import ghost from "../assets/ghost.png";
const Cardcomponentreverse = ({ title }) => {
  return (
    <div>
      <div className="grid grid-cols-2 max-xl:grid-cols-1 gap-6 mt-6 py-14 px-12 relative lg:items-center ">
      <div>
          <div className="card-body pr-8">
            <h5 className="card-title">{title}</h5>
            <p className="card-text">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud. Lorem ipsum dolor sit amet,
              consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
              labore et dolore magna aliqua. Lorem ipsum dolor sit amet,
              consectetur adipiscing elit.
            </p>
            <div>
              <div className="flex justify-center mt-10">
                <div className="flex items-center mr-5">
                  <img src={players} className="team-goals-img" alt="..." />
                  <p className="team-goals-text card-text">3-7 players</p>
                </div>
                <div className="flex items-center">
                  <img src={clock} className="team-goals-img" alt="..." />
                  <p className="team-goals-text card-text">60 mins</p>
                </div>
              </div>
            </div>
            <div>
              <div className="flex justify-between mt-5">
                <div className="flex items-center mr-5">
                  <img src={ghost} className="team-goals-img" alt="..." />
                  <p className="team-goals-text card-text">
                    Team collaboration
                  </p>
                </div>
                <div className="flex items-center mr-5">
                  <img src={ghost} className="team-goals-img" alt="..." />
                  <p className="team-goals-text card-text">
                    Team collaboration
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="flex justify-between mt-4">
                <div className="flex items-center mr-5">
                  <img src={ghost} className="team-goals-img" alt="..." />
                  <p className="team-goals-text card-text">
                    Team collaboration
                  </p>
                </div>
                <div className="flex items-center mr-5">
                  <img src={ghost} className="team-goals-img" alt="..." />
                  <p className="team-goals-text card-text">
                    Team collaboration
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <img
            src={cardpicture}
            className="img-fluid rounded-start"
            alt="..."
          />
        </div>
        
      </div>
    </div>
  );
};

export default Cardcomponentreverse;
