import React, { useRef } from "react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons"; // Import icons from Ant Design
import "../../styles/contact.css";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import { CardSwiper } from "./CardSwiper";

const SwiperComponents = () => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const mediaQuery = window.matchMedia("(max-width: 1280px)");

  return (
    <Swiper
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={40}
      slidesPerView={mediaQuery.matches ? 1.3 : 3}
      className="bg-[#121212] mt-10"
      navigation={{
        prevEl: prevRef.current,
        nextEl: nextRef.current,
      }}
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      onSlideChange={() => console.log("slide change")}
      // onSwiper={(swiper) => console.log(swiper)}
    >
      <div ref={prevRef} className="swiper-button-prev custom-nav mx-5">
        <span className="material-symbols-outlined ">west</span>
      </div>
      <div ref={nextRef} className="swiper-button-next custom-nav mx-5">
        <span className="material-symbols-outlined">east</span>
      </div>

      <SwiperSlide >
        <CardSwiper />
      </SwiperSlide>
      <SwiperSlide>
        <CardSwiper />
      </SwiperSlide>
      <SwiperSlide>
        <CardSwiper />
      </SwiperSlide>
      <SwiperSlide>
        <CardSwiper />
      </SwiperSlide>
      <SwiperSlide>
        <CardSwiper />
      </SwiperSlide>
      <SwiperSlide >
        <CardSwiper />
      </SwiperSlide>
    </Swiper>
  );
};

export default SwiperComponents;
