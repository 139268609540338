import { baseApi } from './baseApi';

export const roomsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getRooms: builder.query({
      query: () => '/rooms',
    }),
  }),
  overrideExisting: false,
});

export const { useGetRoomsQuery } = roomsApi;
