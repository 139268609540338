import { createSlice } from '@reduxjs/toolkit';

const faqsSlice = createSlice({
  name: 'faqs',
  initialState: {
    list: [],
  },
  reducers: {
    setFaqs: (state, action) => {
      state.list = action.payload;
    },
  },
});

export const { setFaqs } = faqsSlice.actions;
export default faqsSlice.reducer;
