import React from "react";
import "../../styles/viewtab.css";
import { Tabs } from "antd";
import "../../styles/viewtab.css";
import RoomSwiper from "./RoomSwiper";
import RoomViewInfo from "./RoomViewInfo";
const onChange = (key) => {
  console.log(key);
};
const items = [
  {
    key: "1",
    label: "PLAN A",
    children: (
      <>
        <h2 className="text-center font-roboto-slab font-extrabold text-4xl text-[#efefef] mb-14 mt-8">
          Photo gallery
        </h2>
        <RoomSwiper />
        <RoomViewInfo />
      </>
    ),
  },
  {
    key: "2",
    label: "PLAN B",
    children: <p className="text-justify  content-plan">HI</p>,
  },
];
const RoomViewTab = () => (
  <>
    <div className="bg-newviewtab RoomViewTab mt-8 px-20 max-lg:px-5">
      <Tabs defaultActiveKey="1" items={items} centered onChange={onChange} />
    </div>
  </>
);
export default RoomViewTab;
