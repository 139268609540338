// import React from 'react'
// import { Link } from 'react-router-dom';

// const Navbar = () => {

//     return (
//         <div className='container-fluid'>
//             <nav className="navbar navbar-expand-lg container-fluid fixed-top py-1">
//                 <div className="container-fluid py-2 d-flex justify-content-between">
//                     {/* logo in the navbar */}
//                     <Link to="/">
//                         <img src="imgs/logo.png" alt="logo" width="140" />
//                     </Link>

//                     {/* dropdown */}
//                     <div className="dropdown">
//                         <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas"
//                             data-bs-target="#offcanvasDarkNavbar" aria-controls="offcanvasDarkNavbar" aria-label="Toggle">
//                             <span className="navbar-toggler-icon"></span>
//                         </button>
//                         <div className="offcanvas offcanvas-end w-50" tabindex="-1" id="offcanvasDarkNavbar"
//                             aria-labelledby="offcanvasDarkNavbarLabel">
//                             <div className="offcanvas-header">
//                                 <h5 className="offcanvas-title fs-5" id="offcanvasDarkNavbarLabel"><img src="imgs/logo.png"
//                                     className="img-fluid w-50" /></h5>
//                                 <button type="button" className="btn-close btn-close-white" data-bs-dismiss="offcanvas"
//                                     aria-label="Close"></button>
//                             </div>
//                             <div className="offcanvas-body">
//                                 <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
//                                     <li className="nav-item">
//                                         <Link className="nav-link" aria-current="page" to="/about">About Us</Link>
//                                     </li>
//                                     <li className="nav-item">
//                                         <Link className="nav-link" to="#">Rooms</Link>
//                                     </li>
//                                     <li className="nav-item">
//                                         <Link className="nav-link" to="#">Services</Link>
//                                     </li>
//                                     <li className="nav-item">
//                                         <Link className="nav-link" to="#">Contact Us</Link>
//                                     </li>
//                                 </ul>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="collapse navbar-collapse justify-content-center" id="navbarSupportedContent">
//                         <ul className="navbar-nav mx-auto mb-2 mb-lg-0 fs-5">
//                             <li className="nav-item"><Link to="/about" className="nav-link">About Us</Link></li>
//                             <li className="nav-item"><Link to="#" className="nav-link">Rooms</Link></li>
//                             <li className="nav-item"><Link to="#" className="nav-link">Services</Link></li>
//                             <li className="nav-item"><Link to="#" className="nav-link">Contact Us</Link></li>
//                         </ul>
//                         {/* navbar links */}
//                         <div className="sign-up pl-2">
//                             <button className="btn p-2 px-4">SIGN UP</button>
//                         </div>
//                     </div>
//                 </div>
//             </nav>

//         </div>
//     );
// }

// export default Navbar

import React, { useState } from "react";
import "../styles/main.css";
import { Link, NavLink } from "react-router-dom";
import { Menu, Button, Drawer } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import logo from "../assets/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { clearAuth } from "../features/authSlice";
// import { useTranslation } from 'react-i18next';

const { SubMenu } = Menu;

const Nav = () => {
    const dispatch = useDispatch();

    // Access the auth state from Redux
    const { user } = useSelector((state) => state.auth);

    // Sign out handler
    const handleSignOut = () => {
      dispatch(clearAuth()); // Dispatch clearAuth to log the user out
    };

  let Links = [
    { name: "About Us", name_ar: "عنا", link: "/about" },
    { name: "Rooms", name_ar: "الغرف", link: "/rooms" },
    { name: "Services", name_ar: "خدماتنا", link: "/services" },
    { name: "Contact Us", name_ar: "تواصل معنا", link: "/contact" },
  ];

  const [open, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <div
    // dir={language == "ar" && 'ltr'}
    >
      <div className="nav flex items-center justify-between bg-transparent py-4 px-24 max-lg:px-5 max-lg:hidden lg:flex relative z-10">
        <Link
          to="/"
          className="font-bold text-3xl max-lg:text-xl cursor-pointer flex items-center"
          style={{ fontFamily: 'Cinzel Decorative' }}
        >
          <img src={logo} alt="logo" width="190" />
        </Link>
        <div className="mx-auto flex">
          <ul
            //   dir={language == "ar" && 'rtl'}
            className={`flex mx-auto items-center md:static md:z-auto z-[-1] left-0 md:w-auto md:pl-0 pl-9 transition-all duration-500 ease-in`}
          >
            {Links.map((link) => (
              <li
                key={link.name}
                className="mx-4 text-lg my-0 max-lg:my-7 font-[ Cinzel Decorative]"
              >
                <NavLink
                  to={link.link}
                  className={({ isActive }) =>
                    isActive
                      ? 'hover:#F4EB19 active:#F4EB19 duration-500 text-[#F4EB19]'
                      : 'hover:#AD834E active:#AD834E duration-500 text-white'
                  }
                >
                  {link.name}
                  {/* {language == "en" ? link.name : link.name_ar} */}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>

        {user ? (
          // Render Sign Out button if user is signed in
          <button
            className="btn p-2 px-6 text-[#F4EB19] border-2 border-[#F4EB19] rounded-3xl"
            onClick={handleSignOut}
          >
            SIGN OUT
          </button>
        ) : (
          // Render Sign Up link if user is not signed in
          <Link
            to="/signup"
            className="sign-up px-2 rounded-3xl border-2 border-[#F4EB19]"
          >
            <button className="btn p-2 px-6 text-[#F4EB19]">SIGN UP</button>
          </Link>
        )}
      </div>

      <div className="nav flex items-center justify-between py-4 max-lg:px-5 lg:hidden max-lg:flex relative z-10">
        <Link
          to="/"
          className="font-bold text-3xl max-lg:text-xl cursor-pointer flex items-center"
          style={{ fontFamily: 'Cinzel Decorative' }}
        >
          <img src={logo} alt="logo" width="140" />
        </Link>

        <div>
          <Button
            type="primary"
            className="bg-transparent"
            onClick={showDrawer}
          >
            <MenuOutlined />
          </Button>
          <Drawer title="" placement="right" onClose={onClose} open={open}>
            <Menu
              // mode="inline"
              className="border-e-0 bg-[#D6BA2A] text-white"
              //   dir={language == "ar" && 'rtl'}
            >
              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive
                    ? 'hover:#AD834E active:#AD834E duration-500 text-lg block mb-7 font-[ Cinzel Decorative] text-[#202020]'
                    : 'hover:#AD834E active:#AD834E duration-500 text-lg block mb-7 font-[ Cinzel Decorative]'
                }
              >
                Home
              </NavLink>
              {Links.map((link) => (
                <NavLink
                  to={link.link}
                  key={link.name}
                  className={({ isActive }) =>
                    isActive
                      ? 'hover:#202020 active:#202020 duration-500 text-lg block mb-7 font-[ Cinzel Decorative] text-[#202020]'
                      : 'hover:#202020 active:#202020 duration-500 text-lg block mb-7 font-[ Cinzel Decorative]'
                  }
                >
                  {link.name}
                  {/* {language == "en" ? link.name : link.name_ar} */}
                </NavLink>
              ))}
            </Menu>
          </Drawer>
        </div>
      </div>
    </div>
  );
};

export default Nav;
