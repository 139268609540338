import React, { useState } from 'react';
import { Tabs, Button } from 'antd';
import sleeping from '../../assets/sleeping-mask.png';
import lock from '../../assets/lock.png';
import stopwatch from '../../assets/stopwatch.png';
import door from '../../assets/door.png';
import Terror from './Terror';
import terror from '../../assets/terror.png';
import { LeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { useGetHomeContentQuery } from '../../api/content/homeContentApi';

const Trapped = () => {
  const { data: homeContent, error, isLoading } = useGetHomeContentQuery();

  const firstTab = homeContent.find(
    (item) => item.section.name_en === 'First tab'
  );
  const secondTab = homeContent.find(
    (item) => item.section.name_en == 'Second tab'
  );
  const thirdTab = homeContent.find(
    (item) => item.section.name_en == 'Third tab'
  );
  const fourthTab = homeContent.find(
    (item) => item.section.name_en == 'Fourth tab'
  );

  const allTabs = [
    {
      key: '1',
      label: (
        <img src={sleeping} width="60px" className="w-[60px] max-lg:w-[39px]" />
      ),
      children: (
        <Terror
          number="01"
          title={firstTab.title_en}
          desc={firstTab.desc_en}
          image={firstTab.image}
        />
      ),
    },
    {
      key: '2',
      label: (
        <img src={lock} width="60px" className="w-[60px] max-lg:w-[35px]" />
      ),
      children: (
        <Terror
          number="02"
          title={secondTab.title_en}
          desc={secondTab.desc_en}
          image={secondTab.image}
        />
      ),
    },
    {
      key: '3',
      label: (
        <img
          src={stopwatch}
          width="60px"
          className="w-[60px] max-lg:w-[35px]"
        />
      ),
      children: (
        <Terror
          number="03"
          title={thirdTab.title_en}
          desc={thirdTab.desc_en}
          image={thirdTab.image}
        />
      ),
    },
    {
      key: '4',
      label: (
        <img src={door} width="60px" className="w-[60px] max-lg:w-[35px]" />
      ),
      children: (
        <Terror
          number="04"
          title={fourthTab.title_en}
          desc={fourthTab.desc_en}
          image={fourthTab.image}
        />
      ),
    },
  ];

  const [currentTab, setCurrentTab] = useState(0);

  const handlePrev = () => {
    setCurrentTab((prev) => (prev > 0 ? prev - 1 : allTabs.length - 1));
  };

  const handleNext = () => {
    setCurrentTab((prev) => (prev < allTabs.length - 1 ? prev + 1 : 0));
  };

  return (
    <div className="mt-10 relative h-full Trapped">
      <div className="flex items-center justify-center mb-4">
        {/* <Button onClick={handlePrev} icon={<LeftOutlined />} /> */}
        <Tabs
          activeKey={String(currentTab + 1)}
          onChange={(key) => setCurrentTab(parseInt(key) - 1)}
        >
          {allTabs.map((tab) => (
            <Tabs.TabPane tab={tab.label} key={tab.key}>
              {tab.children}
            </Tabs.TabPane>
          ))}
        </Tabs>
        <span
          className="material-symbols-outlined cursor-pointer absolute bottom-10 max-lg:-bottom-16 mb-3 left-96 max-lg:left-56 bg-transparent border-0 text-[#F4EB19] text-7xl"
          onClick={handleNext}
        >
          trending_flat
        </span>
      </div>

      {/* Pagination Dots */}
      <div className="flex justify-center space-x-2 mt-4 absolute bottom-20 max-lg:-bottom-8 left-1/4 max-lg:left-32">
        {allTabs.map((_, index) => (
          <div
            key={index}
            onClick={() => setCurrentTab(index)}
            style={{
              width: currentTab === index ? '25px' : '10px',
              height: '8px',
              borderRadius: currentTab === index ? '10px' : '50%',
              backgroundColor: currentTab === index ? '#EDE755' : '#F6EFCA',
              cursor: 'pointer',
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default Trapped;
