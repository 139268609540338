import React from 'react'

import RoomViewTab from '../components/room-view/RoomViewTab'
import RoomViewHome from '../components/room-view/RoomViewHome'


const RoomView = () => {
  return (
    <>

      <RoomViewHome />
      <RoomViewTab  />
    </>
  )
}

export default RoomView