import React, { useEffect, useState } from "react";
import Bundle from "./Bundle";
import { DatePicker, Radio } from "antd";
import dayjs from "dayjs";
import "antd/dist/reset.css";
import "../../styles/book.css";
import { Link } from "react-router-dom";

const BookingForm = () => {
  const [selectedBundle, setSelectedBundle] = useState(null);

  const bundles = [
    { playerNumber: 3, price: 350 },
    { playerNumber: 4, price: 320 },
    { playerNumber: 5, price: 300 },
    { playerNumber: 6, price: 290 },
    { playerNumber: 7, price: 250 },
    { playerNumber: 8, price: 200 },
  ];

  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedDay, setSelectedDay] = useState(null);
  const [availableDays, setAvailableDays] = useState([]);
  const [selectedTime, setSelectedTime] = useState(null);
  
  const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  const daysInMonth = (month, year) => new Date(year, month + 1, 0).getDate();

  // Dummy data fetching function
  const fetchAvailableDays = (month, year) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        // Example dummy data for available days based on month and year
        const dummyData = {
          '2024-0': [3, 10, 15, 20], // January 2024 (month is 0-indexed)
          '2024-1': [5, 12, 19, 25], // February 2024
          '2024-8': [1, 6, 7, 8, 11, 12, 13, 14, 16,17,19, 27,28 ,29 , 30],  //seb
          '2024-9': [1, 7, 19, 29],  
          '2024-10': [1, 4, 19, 29 , 9 , 13 , 24],  
        };
        resolve(dummyData[`${year}-${month}`] || []); // Return available days or an empty array
      }, 1000); // Simulate a delay
    });
  };

  // Fetch available days from dummy data whenever month or year changes
  useEffect(() => {
    const fetchDays = async () => {
      const days = await fetchAvailableDays(selectedMonth, selectedYear);
      setAvailableDays(days);
    };
    fetchDays();
  }, [selectedMonth, selectedYear]);

  // Get an array of day objects for the selected month
  const daysArray = Array.from({ length: daysInMonth(selectedMonth, selectedYear) }, (_, i) => ({
    day: i + 1,
    available: availableDays.includes(i + 1)
  }));

  const availableTimes = [
    "11:00 AM - 12:00 PM",
    "12:00 PM - 1:00 PM",
    "1:00 PM - 2:00 PM",
    "11:00 PM - 12:00 PM",
    "8:00 PM - 9:00 PM",
  ];

  console.log(selectedDay , selectedTime);

  return (
    <div className="px-24 max-lg:px-5">
      <h2 className="text-4xl max-lg:text-2xl font_math text-[#EFEFEF]">
        Choose your bundle
      </h2>
      <form>
        <div className="grid grid-cols-2 max-xl:grid-cols-1 gap-12 my-10">
          {/* 
          <Bundle playerNumber="3" price="350" />
          <Bundle playerNumber="4" price="320" />
          <Bundle playerNumber="5" price="300" />

          <Bundle playerNumber="6" price="290" />
          <Bundle playerNumber="6" price="290" />
          <Bundle playerNumber="6" price="290" /> */}

          {bundles.map((bundle, index) => (
            <Bundle
              key={`${bundle.playerNumber}-${bundle.price}`}
              playerNumber={bundle.playerNumber}
              price={bundle.price}
              selected={selectedBundle === bundle.playerNumber}
              onClick={() => setSelectedBundle(bundle.playerNumber)}
            />
          ))}
        </div>

        {/* ////////////////////////////////////////////////////////////// */}

        {/* Time Selection */}
        {/* <div className="space-y-4">
          <h4 className="text-2xl font-semibold text-[#EFEFEF]">
            Available times
          </h4>
          <Radio.Group
            onChange={handleTimeChange}
            value={selectedTime}
            className="block"
          >
            {times.map((time) => (
              <Radio.Button
                key={time}
                value={time}
                className={`block w-full py-3 text-lg rounded-lg border border-[#EFEFEF] hover:bg-yellow-500 transition-colors ${
                  selectedTime === time ? "bg-yellow-500" : ""
                }`}
              >
                {time}
              </Radio.Button>
            ))}
          </Radio.Group>
        </div> */}

        {/* <div className="grid grid-cols-2 max-xl:grid-cols-1 gap-4 mt-24 mb-10 text-[#EFEFEF]">
          <div>
            <h2 className="text-[#F3DB29] text-4xl max-lg:text-2xl font-semibold font_math">
              Thursday, December 24
            </h2>
            <div className="grid grid-cols-7 max-xl:grid-cols-7 gap-4 text-[#E9E5DD] text-2xl font_math text-center">
              <p>Sat</p>
              <p>Sun</p>
              <p>Mon</p>
              <p>Tues</p>
              <p>Wed</p>
              <p>Thurs</p>
              <p>Fri</p>
            </div>
            <div className="grid grid-cols-7 max-xl:grid-cols-7 gap-4 text-[#E9E5DD] font-semibold text-2xl text-center">
              <p className="text-[#313131] mb-1">31</p>
              <p className="text-[#DAD7C1] mb-1">01</p>
              <p className="text-[#DAD7C1] mb-1">02</p>
              <p className="text-[#313131] mb-1">03</p>
              <p className="text-[#DAD7C1] mb-1">04</p>
              <p className="text-[#DAD7C1] mb-1">05</p>
              <p className="text-[#DAD7C1] mb-1">06</p>

              <p className="text-[#DAD7C1] mb-1">07</p>
              <p className="text-[#DAD7C1] mb-1">08</p>
              <p className="text-[#DAD7C1] mb-1">09</p>
              <p className="text-[#DAD7C1] mb-1">10</p>
              <p className="text-[#DAD7C1] mb-1">11</p>
              <p className="text-[#DAD7C1] mb-1">12</p>
              <p className="text-[#313131] mb-1">13</p>

              <p className="text-[#DAD7C1] mb-1">14</p>
              <p className="text-[#DAD7C1] mb-1">15</p>
              <p className="text-[#313131] mb-1">16</p>
              <p className="text-[#DAD7C1] mb-1">17</p>
              <p className="text-[#313131] mb-1">18</p>
              <p className="text-[#DAD7C1] mb-1 border-2 border-[#F3DB29] rounded-full">
                19
              </p>
              <p className="text-[#DAD7C1] mb-1">20</p>

              <p className="text-[#DAD7C1] mb-1">21</p>
              <p className="text-[#DAD7C1] mb-1">22</p>
              <p className="text-[#DAD7C1] mb-1">23</p>
              <p className="text-[#DAD7C1] mb-1">24</p>
              <p className="text-[#DAD7C1] mb-1">25</p>
              <p className="text-[#DAD7C1] mb-1">26</p>
              <p className="text-[#DAD7C1] mb-1">27</p>

              <p className="text-[#DAD7C1] mb-1">28</p>
              <p className="text-[#313131] mb-1">29</p>
              <p className="text-[#DAD7C1] mb-1">30</p>
              <p className="text-[#313131] mb-1">01</p>
              <p className="text-[#313131] mb-1">02</p>
              <p className="text-[#313131] mb-1">03</p>
              <p className="text-[#313131] mb-1">04</p>
            </div>
            <div></div>
          </div>
          <div className="bg-[#0A0A0A] text-[#EFEFEF] rounded-xl py-7">
            <h2 className="text-[#EFEFEF] text-3xl max-lg:text-2xl text-center font-semibold mb-4">
              Available times
            </h2>
            <div className="flex justify-center text-2xl">
              <div>
                <div className="bg-[#181818] text-[#DAD7C1] rounded-3xl px-9 py-3 mt-4">
                  11:00 AM - 12:00 PM
                </div>
                <div className="bg-[#181818] text-[#DAD7C1] rounded-3xl px-9 py-3 mt-4">
                  11:00 AM - 12:00 PM
                </div>
                <div className="bg-[#181818] text-[#DAD7C1] rounded-3xl px-9 py-3 mt-4">
                  11:00 AM - 12:00 PM
                </div>
                <div className="bg-[#181818] text-[#DAD7C1] rounded-3xl px-9 py-3 mt-4">
                  11:00 AM - 12:00 PM
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div className="grid grid-cols-7 max-xl:grid-cols-1 gap-10 mt-24 mb-10 text-[#EFEFEF]">
          <div className="col-span-4">
            {/* Month and Year Selectors */}
            <div className="flex items-center mb-4">
              <select
                value={selectedMonth}
                onChange={(e) => setSelectedMonth(parseInt(e.target.value))}
                className="bg-[#121212] border-0 text-[#F3DB29] p-2 text-4xl max-lg:text-2xl font-semibold font_math"
              >
                {months.map((month, index) => (
                  <option key={index} value={index}>
                    {month}
                  </option>
                ))}
              </select>
              <select
                value={selectedYear}
                onChange={(e) => setSelectedYear(parseInt(e.target.value))}
                className="bg-[#121212] text-[#F3DB29] p-2 text-4xl max-lg:text-2xl font-semibold font_math"
              >
                {Array.from({ length: 10 }, (_, i) => selectedYear - 5 + i).map(
                  (year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  )
                )}
              </select>
            </div>

            {/* Days of the week */}
            <div className="grid grid-cols-7 gap-4 text-[#E9E5DD] text-2xl font_math text-center">
              {["Sat", "Sun", "Mon", "Tues", "Wed", "Thurs", "Fri"].map(
                (day, index) => (
                  <p key={index}>{day}</p>
                )
              )}
            </div>

            {/* Days in month */}
            <div className="grid grid-cols-7 gap-4 text-[#E9E5DD] font-semibold text-2xl text-center">
              {daysArray.map(({ day, available }) => (
                <p
                  key={day}
                  className={`mb-1 cursor-pointer ${
                    available ? "text-[#DAD7C1]" : "text-[#313131]"
                  } 
              ${
                selectedDay === day
                  ? "border-2 border-[#F3DB29] rounded-full"
                  : ""
              }`}
                  onClick={() => available && setSelectedDay(day)}
                >
                  {day}
                </p>
              ))}
            </div>
          </div>

          <div className="col-span-3 bg-[#0A0A0A] text-[#EFEFEF] rounded-xl py-7 px-5">
            <h2 className="text-[#EFEFEF] text-3xl max-lg:text-2xl text-center font-semibold mb-4">
              Available times
            </h2>
            <div className="flex justify-center text-2xl available_time">
              <div>
                {availableTimes.map((time, index) => (
                  <div
                    key={index}
                    className={`bg-[#181818] text-[#DAD7C1] rounded-3xl px-9 py-3 mt-4 cursor-pointer
                ${selectedTime === time ? "border-2 border-[#F3DB29]" : ""}`}
                    onClick={() => setSelectedTime(time)}
                  >
                    {time}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Proceed Button */}
        <Link to="/pay" className="flex justify-center mb-14">
          <button
            className={`mt-6 px-14 py-3 text-lg rounded-lg text-[#EFEFEF] bg-[#D6BA2A] font-bold`}
          >
            PROCEED
          </button>
        </Link>
      </form>
    </div>
  );
};

export default BookingForm;
