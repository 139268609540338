import React from "react";
import { Link } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import mastercard from "../../assets/mastercard.png";
import visa from "../../assets/visa.png";
import Bundle from "../BookNow/Bundle";
import FormInput from "../FormInput";
import { Input } from "antd";

const Summary = () => {
  return (
    <div className="py-10">
      {/* <img
        src={GroupAbout}
        width="380px"
        className="lg:mr-20 rounded-xl relative z-10 max-lg:w-[230px]"
      /> */}
      <Link
        to="/booknow"
        className="text-[#F4EB19] text-xl font-bold flex items-center "
      >
        <LeftOutlined className="size-5 mr-2" />
        Back to Booking
      </Link>
      <h2 className="text-5xl max-lg:text-2xl font_math text-[#EFEFEF] mt-20">
        Booking summary
      </h2>
      <div className="grid grid-cols-2 max-xl:grid-cols-1 gap-6 lg:items-center px-20 mt-5">
        <Bundle playerNumber="6" price="290" />
        <div className="text-[#E9E5DD] text-center">
          <h2 className="text-3xl">Thursday, December 19</h2>
          <h3 className="text-xl text-[#B0B0B0]">
            From 01:00 PM till 02:00 PM
          </h3>
        </div>
      </div>
      <div className="w-2/3 grid grid-cols-3 max-xl:grid-cols-1 gap-6 lg:items-center lg:justify-center mx-auto mt-9">
        <div className="relative col-span-2">
          <Input
            className="MyInput placeholder:text-xl placeholder:text-center"
            placeholder="Promo code"
          />
          {/* <label className="input-label">{"Last name"}</label> */}
        </div>
        <button
          className={`px-10 py-3 text-lg rounded-lg text-[#EFEFEF] bg-[#C7A807] font-bold`}
        >
          APPLY
        </button>
      </div>

      <div>
        <h2 className="text-5xl max-lg:text-2xl font_math text-[#EFEFEF] mt-20">
          Your details
        </h2>
        <div className="grid grid-cols-2 max-xl:grid-cols-1 gap-4 mt-3 ml-7">
          <div className="relative">
            <Input
              className="MyInput placeholder:text-xl mb-3"
              placeholder="First name"
            />
            <label className="input-label">{"First name"}</label>
          </div>
          <div className="relative">
            <Input
              className="MyInput placeholder:text-xl mb-3"
              placeholder="Last name"
            />
            <label className="input-label">{"Last name"}</label>
          </div>
          <div className="relative">
            <Input
              className="MyInput placeholder:text-xl mb-3"
              placeholder="Email address"
            />
            <label className="input-label">{"Email address"}</label>
          </div>
          <div className="relative">
            <Input
              className="MyInput placeholder:text-xl mb-3"
              placeholder="Mobile number"
            />
            <label className="input-label">{"Mobile number"}</label>
          </div>
        </div>
      </div>

      <div>
        <h2 className="text-5xl max-lg:text-2xl font_math text-[#EFEFEF] mt-10">
          Price summary
        </h2>
        <div className="ml-7 grid grid-cols-5 max-xl:grid-cols-1 gap-0 bg-[#dcc23b2e] text-[#DCC23B]">
          <div className="col-span-2 p-4 border border-[#DCC23B]">Item</div>
          <div className="text-center p-4 border border-[#DCC23B]">Amount</div>
          <div className="text-center p-4 border border-[#DCC23B]">Qty</div>
          <div className="text-center p-4 border border-[#DCC23B]">Subtotal</div>
        </div>
        <div className="ml-7 grid grid-cols-5 max-xl:grid-cols-1 gap-0 text-[#D7CEA2]">
          <div className="col-span-2 p-4 py-7 border border-[#DCC23B]">Item</div>
          <div className="text-center p-4 py-7 border border-[#DCC23B]">Amount</div>
          <div className="text-center p-4 py-7 border border-[#DCC23B]">Qty</div>
          <div className="text-center p-4 py-7 border border-[#DCC23B]">Subtotal</div>
        </div>
        <div className="text-center font-bold w-1/4 mx-auto mt-3">
          <div className="flex justify-between items-center text-lg px-10">
            <p className="text-[#DCC23B]">Subtotal</p>
            <p className="text-[#D7CEA2]">2,100 EGP</p>
          </div>
          <div className="flex justify-between items-center text-lg px-10">
            <p className="text-[#DCC23B]">Discount</p>
            <p className="text-[#D7CEA2]">360 EGP</p>
          </div>
          <hr className="border border-[#605c47] bg-[#605c47]"/>
          <div className="flex justify-between items-center text-2xl mt-3 px-5">
            <p className="text-[#DCC23B]">TOTAL</p>
            <p className="text-[#D7CEA2]">1, 740 EGP</p>
          </div>
        </div>
      </div>

      <div>
        <h2 className="text-5xl max-lg:text-2xl font_math text-[#EFEFEF] mt-20">
          Payment details
        </h2>
        <div className="relative ml-7">
          <Input
            placeholder="Card number"
            className="MyInput placeholder:text-xl placeholder:text-[#D7CEA2] mb-5"
            suffix={
              <div className="flex items-center">
                <img src={visa} width="30px" />
                <img src={mastercard} width="30px" className="ml-2" />
              </div>
            }
          />
          <label className="input-label">{"Card number"}</label>
        </div>
        <div className="grid grid-cols-2 max-xl:grid-cols-1 gap-6 ml-7">
          <div className="relative">
            <Input
              className="MyInput placeholder:text-xl mb-3"
              placeholder="MM/YYYY"
            />
            <label className="input-label">{"Expiry date"}</label>
          </div>
          <div className="relative">
            <Input
              className="MyInput placeholder:text-xl mb-3"
              placeholder="CVV"
            />
            <label className="input-label">{"CVV"}</label>
          </div>
        </div>
      </div>

      <button
        className={`mt-6 px-14 py-3 text-lg rounded-lg text-[#EFEFEF] bg-[#D6BA2A] font-bold mx-auto flex`}
      >
        PAY NOW
      </button>
    </div>
  );
};

export default Summary;
