import { baseApi } from '../baseApi';

export const homeContentApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getHomeContent: builder.query({
      query: () => '/contents/home',
    }),
  }),
  overrideExisting: false,
});

export const { useGetHomeContentQuery } = homeContentApi;
