import React from 'react';
import qoute from '../../assets/qoute.png';
import { Rate } from 'antd';

export const CardSwiper = () => {
  return (
    <>
      <div className="bg-[#0A0A0A] p-6 rounded-xl w-5/6 mx-auto">
        <img src={qoute} className="w-[40px] max-lg:w-[30px]" />
        <div className="py-8 px-4 max-lg:p-4">
          <h3 className="text-white slider-name text-center text-lg ">
            Samir Ahmed
          </h3>
          <div>
            <Rate
              allowHalf
              defaultValue={5}
              className="custom-rate  flex justify-center text-center"
            />
          </div>
          <p className="text-white  slider-text text-center">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.{' '}
          </p>
        </div>
      </div>
    </>
  );
};
