import { createSlice } from '@reduxjs/toolkit';

const socialMediaSlice = createSlice({
  name: 'socialMedia',
  initialState: {
    list: [],
    facebook: null,
    whatsApp: null,
    instagram: null,
    twitter: null,
    tiktok: null,
  },
  reducers: {
    setSocialMedia: (state, action) => {
      state.list = action.payload;
      state.facebook = action.payload.find(
        (item) => item.type_en === 'Facebook'
      );
      state.whatsApp = action.payload.find(
        (item) => item.type_en === 'WhatsApp'
      );
      state.instagram = action.payload.find(
        (item) => item.type_en === 'Instagram'
      );
      state.twitter = action.payload.find((item) => item.type_en === 'Twitter');
      state.tiktok = action.payload.find((item) => item.type_en === 'Tiktok');
    },
  },
});

export const { setSocialMedia } = socialMediaSlice.actions;
export default socialMediaSlice.reducer;
