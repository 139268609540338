import React from "react";
import "../styles/news.css";
import FormInput from "./FormInput";
import '../styles/form-comment.css';
import LabelForm from "./LabelForm";
const NewsForm = ({ label, ...props }) => {
  return (
    <div>
      <div className="big-container-form">
        <div className="small-container">
          <h2 className="text-center headStyle">HAVE A COMMENT ?</h2>

         
<LabelForm />
          <div className="text-center">
            <button
              type="submit"
              className="btn btn-primary btn-lg local py-4 px-16 "
            >
              SEND COMMENT
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsForm;
