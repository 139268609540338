import { createSlice } from '@reduxjs/toolkit';

const homeContentSlice = createSlice({
  name: 'homeContent',
  initialState: {
    list: [],
  },
  reducers: {
    setHomeContent: (state, action) => {
      state.list = action.payload;
    },
  },
});

export const { setHomeContent } = homeContentSlice.actions;
export default homeContentSlice.reducer;
