import React from 'react';
import Footer from './Footer';
import Navbar from './Navbar';
import { useGetSocialMediaQuery } from '../api/socialMediaApi';
import { useGetContactContentQuery } from '../api/content/contactContentApi';
import { useGetAboutContentQuery } from '../api/content/aboutContentApi';

const Layout = ({ children }) => {

  const { isLoading, isError } = useGetSocialMediaQuery();
  const { isLoading: isContactLoading, isError: isContactError } = useGetContactContentQuery();
  const { isLoading: isAboutLoading, isError: isAboutError } = useGetAboutContentQuery();

  if (isLoading || isContactLoading || isAboutLoading) {
    return <div className="text-center fs-2">Loading...</div>;
  }

  if (isError || isContactError || isAboutError) {
    return (
      <div className="text-center text-red-600 fs-2">Something went error!</div>
    );
  }

  return (
    <div>
      <Navbar />
      <main>{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
