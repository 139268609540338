import React from 'react'
import Newsview from '../components/Newsview';
import Newsviewtaps from '../components/Newsviewtaps'; 
import NewsForm from '../components/NewsForm';
const NewsView = () => {
  return (
  <>
  <Newsview />
  <Newsviewtaps/>
  <NewsForm />
  </>
  )
}

export default NewsView