import { baseApi } from './baseApi';

export const socialMediaApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getSocialMedia: builder.query({
      query: () => '/social_media',
    }),
  }),
  overrideExisting: false,
});

export const { useGetSocialMediaQuery } = socialMediaApi;
